import React from "react";
import { BrowserRouter as Router, Switch, Route, Link  } from "react-router-dom";


import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Sidebar_user from "./components/Sidebar_user";
import Sidebar_anfitrion from "./components/Sidebar_anfitrion";
import Sidebar_interventor from "./components/Sidebar_interventor";
import Home from "./pages/Home";
import Home_user from "./pages/Home_user";
import Home_anfitrion from "./pages/Home_anfitrion";
import Puntos_Bisinu from "./pages/Puntos_Bisinu";
import Mapa from "./pages/Mapa";
import Reportes from "./pages/Reportes";
import Reportes_interventor from "./pages/Reportes_interventor";
import Registrar from "./pages/Registrar"
import Login from './pages/Login'
import Login_user from './pages/Login_user'
import Prestamos_user from "./pages/Prestamos_user";
import Prestamos_anfitrion from "./pages/Prestamos_anfitrion";
import Reportar_taller from "./pages/Reportar_taller";
import Usuario_taller from "./pages/Usuario_taller";
import Mi_cuenta from "./pages/Mi_cuenta";
import Puntos_web from "./pages/Puntos_web";

import Pagina404 from "./pages/Pagina404";

function App(){
    return(
       
        <Router>
            
            <Switch>

              <Route exact path="/" >
               
                <Login/>
             
              </Route>              

              <Route path="/Registrar">
               
                <Registrar/>
              
              </Route> 

              <Route path="/Puntos_Web">
               
                <Puntos_web/>
              
              </Route>


              <Route path="/Home">
                <Navbar/>
                <Sidebar/>
                <Home/>
                <Footer/>
              </Route>  

              <Route path="/Mapa">
                <Navbar/>
                <Sidebar/>
                <Mapa/>
                <Footer/>
              </Route>

              <Route path="/Reportes">
                <Navbar/>
                <Sidebar/>
                <Reportes/>
                <Footer/>
              </Route>


              <Route path="/Reportes_interventor">
                <Navbar/>
                <Sidebar_interventor/>
                <Reportes_interventor/>
                <Footer/>
              </Route>

              <Route path="/gestion_BISINU">
                <Navbar/>
                <Sidebar/>
                <Puntos_Bisinu/>
                <Footer/>
              </Route>

              <Route path="/usuario_taller">
                <Navbar/>
                <Sidebar/>
                <Usuario_taller/>
                <Footer/>
              </Route>  

              <Route exact path="/Login_user" >
               
                <Login_user/>
             
              </Route>

              <Route path="/home_user">
                <Navbar/>
                <Sidebar_user/>
                <Home_user/>
                
              </Route>

              <Route path="/mi_cuenta_user">
                <Navbar/>
                <Sidebar_user/>
                <Mi_cuenta/>
                
              </Route>


              <Route path="/prestamos_user">
                <Navbar/>
                <Sidebar_user/>
                <Prestamos_user/>
                
              </Route> 

              <Route path="/home_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Home_anfitrion/>
                
              </Route>

              <Route path="/mi_cuenta_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Mi_cuenta/>
                
              </Route>

              <Route path="/prestamos_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Prestamos_anfitrion/>
                
              </Route>

              <Route path="/reportar_taller">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Reportar_taller/>
                
              </Route>  

              <Route  path="**">              
               <Pagina404/>                   
              </Route>              
             
              


            </Switch>
           

        </Router>
    )
}

export default App;