import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import '../tools/css/Sidebar.css';
import LogoTexto from '../tools/Imagenes/Logoletter.svg'
import LogoBici from '../tools/Imagenes/Logobici.png'


var Nombre_usuario =sessionStorage.getItem('nombre_usua');
var id_user =sessionStorage.getItem('id_user');
var tipo_id_user =sessionStorage.getItem('tipo_id_user');
var num_id_user =sessionStorage.getItem('num_id_user');
var Avatar =sessionStorage.getItem('avatar');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

class Sidebar_user extends Component{

  

  Buscar_usuario=async()=>{  


      await axios.get(ApiURL+"/api/Users/GetUser/"+tipo_id_user+"/"+num_id_user)  
      .then(response=>{
        console.log(response.data.data)

        var tipo_user=response.data.data.rol.description
       
        if(tipo_user=="ANFITRION"){

          window.location.href="/home_anfitrion";  

        }else{

          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: 'No tienes permiso para ingresar con este perfil!!!',
            showConfirmButton: true,
            timer: 10000
          })

        } 

      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          
          this.setState({
            form:{   
              num_id_user:this.state.form.num_id_user,  
              nom_user: "",      
              obser_dev:"" 
            }
          })
          this.setState({Prestamos:[]})

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

  
  }

  render(){
    return(        
      

        <aside className="main-sidebar sidebar-primary elevation-4" id="sidebar">
  
            <a href="/home_user" className="brand-link">
              <img src={LogoBici} alt="BISINU" className="brand-image img-rounded" />
              <span className="brand-text">APP BISINÚ</span>
            </a>
           
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={Avatar} className="img-circle elevation-2" alt="User Image" />
                </div>
                <div className="info">
                  <a href="#" className="d-block">{Nombre_usuario.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</a>
                  <b>Usuario</b>
                </div>
              </div>
             
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                  {/* Add icons to the links using the .nav-icon class
                   with font-awesome or any other icon font library */}
                 
                  
                 
                  <li className="nav-item">
                    <a href="/home_user" className="nav-link">
                      <i className="nav-icon fas fa-home" />
                      <p>
                        Inicio                        
                      </p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="mi_cuenta_user" className="nav-link">
                      <i className="nav-icon fas fa-user" />
                      <p>
                        Mi cuenta
                      </p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/prestamos_user" className="nav-link">
                      <i className="nav-icon fas fa-list-ul" />
                      <p>
                        Mis prestamos
                      </p>
                    </a>
                  </li>
                  
                  <li className="nav-header">UTILITARIOS</li>
                  {/* <li className="nav-item">
                    <a href="/puntos_BISINU" className="nav-link">
                      <i className="nav-icon fas fa-map-marked-alt" />
                      <p>Puntos BISINÚ</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                      <i className="nav-icon fas fa-photo-video" />
                      <p>Publicidad</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                      <i className="nav-icon fas fa-tools" />
                      <p>Taller</p>
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a href="#" onClick={()=> this.Buscar_usuario()} className="nav-link active">
                      <i className="nav-icon fas fa-user-tie" />
                      <p>Soy anfitrión</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-info-circle" />
                      <p>Acerca de</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="https://app.bisinu.com.co/Login_user?user=&pass=&session=false" onClick={()=> sessionStorage.clear()} className="nav-link">
                      <i className="nav-icon fas fa-power-off" />
                      <p>Salir</p>
                    </a>
                  </li>
                  <hr></hr>
                  <p id="texto_final">
                    Version 1.0.0
                  </p>
                 

                
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>

    
    )
  }
}

export default Sidebar_user;  