import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import * as ReactBootStrap from 'react-bootstrap';
import {  GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import '../tools/css/Puntos_Bisinu.css';
import '../tools/css/Paginacion.css';
import Buscar from '../tools/Imagenes/Buscar';

import Marcador from '../tools/Imagenes/Marcador.svg'
import Editar from '../components/Editar';
import Trasladar from '../components/Trasladar';

import $ from 'jquery'; // <-to import jquery

import 'bootstrap';

const ApiURL="https://bisinuapi-dev.azurewebsites.net";
const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var Latitud=8.7715339;
var Longitud=-75.8807398;
var Latitud_taller=8.7715339;
var Longitud_taller=-75.8807398;
var id_estacion=""
var id_bici=""
var id_taller=""
var id_estacion_dest="0"
var id_bicicleta_tras="0"
var buscar_bicicleta='';

const containerStyle = {
  width: '100%',
  height: '450px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const onLoad = marker => {
  Latitud= marker.position.lat()
  Longitud= marker.position.lng()
  Latitud_taller= marker.position.lat()
  Longitud_taller= marker.position.lng()
  //alert(Latitud+ " "+" " +Longitud)
  
}


class Puntos_Bisinu extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Estaciones:[] , 
        Bicicletas:[],
        Pager_Bicicletas: [],
        pager_infobicicletas:'',
        BicicletasXestacion:[],
        Taller:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          nom_estacion:'',  
          dir_estacion:'',
          codigo_bicicleta:'',  
          marco_bicicleta:'',
          nombre_taller:'',
          dir_taller:'',
          tel1_taller:'',
          tel2_taller:'',
          estacion_origen:'',
          buscar_bicicletas:''
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }

    componentDidMount(){
      $('#btn_edita_estacion').hide();   
      $('#btn_edita_bicicleta').hide();
      $('#btn_edita_taller').hide();        
       this.Cargar_Estaciones();
    }

    onMarkerDragEnd = (e) => {   
       
      Latitud=e.lat()
      Longitud=e.lng()
      //alert(Latitud+ " "+" " +Longitud)
      //console.log(e.lat());
      //console.log(e.lng());
         
    };

    onMarkerDragEnd_taller = (e) => {   
       
      Latitud_taller=e.lat()
      Longitud_taller=e.lng()
      //alert(Latitud+ " "+" " +Longitud)
      //console.log(e.lat());
      //console.log(e.lng());
         
    };
  


    //<- muestra modal para crear sede
    handleModalnewpunto()
    {      
      this.Limpiar_campos();
      $('#btn_edita_estacion').hide(); 
      $('#btn_crear_estacion').show();
      $('#modal-newpunto').modal('show');
    }

    handleModalnewbicicleta()
    {      
      this.Limpiar_campos();
      $('#btn_edita_bicicleta').hide(); 
      $('#btn_crear_bicicleta').show();
      $('#modal-newbicicleta').modal('show');
    }

    handleModalnewtaller()
    {      
      this.Limpiar_campos();
      $('#btn_edita_taller').hide(); 
      $('#btn_crear_taller').show();
      $('#modal-newtaller').modal('show');
    }

    handleModal_trasladarbicicleta()
    {
      $('#modal-trasladar_bicicleta').modal('show');
    }

    Get_id_estaciondest = (ed) =>{
      //this.setState({tipoid:id.target.value})
      console.log(ed.target.value)
      id_estacion_dest=(ed.target.value)     
    }

    Get_id_bicicleta = (ib) =>{
      //this.setState({tipoid:id.target.value})
      console.log(ib.target.value)
      id_bicicleta_tras=(ib.target.value)     
    }

    BuscarBicicleta = (bc) =>{
      this.setState({buscar_bicicletas:bc.target.value})
      console.log(bc.target.value)
      buscar_bicicleta=(bc.target.value)
      this.Cargar_BisicletasXtexto(buscar_bicicleta)
     
    }


    Limpiar_campos(){

      this.setState({
        form:{
          nom_estacion:'',  
          dir_estacion:'', 
          codigo_bicicleta:'',  
          marco_bicicleta:'',
          nombre_taller:'',
          dir_taller:'',
          tel1_taller:'',
          tel2_taller:'',
          estacion_origen:''      
        }
      })
    }


    Crear_Estacion=async()=>{

      if(this.state.form.nom_estacion.length==0 || this.state.form.nom_estacion=='' || this.state.form.dir_estacion.length==0 || this.state.form.dir_estacion==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Stations/Create",
  
          {
            name: this.state.form.nom_estacion,
            address: this.state.form.dir_estacion,
            latitude:Latitud,
            longitude: Longitud,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' La estación '+ this.state.form.nom_estacion + ' fué creada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $('#modal-newpunto').modal('hide');
          this.Cargar_Estaciones();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
     }

     Crear_Bicicleta=async()=>{

      if(this.state.form.codigo_bicicleta.length==0 || this.state.form.codigo_bicicleta=='' || this.state.form.marco_bicicleta.length==0 || this.state.form.marco_bicicleta==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Bikes/Create",
  
          {
            code: this.state.form.codigo_bicicleta,
            number: this.state.form.marco_bicicleta,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' La bicicleta con código: '+ this.state.form.codigo_bicicleta + ' fué creada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-newbicicleta').modal('hide');
          this.Cargar_Bisicletas();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
     }

     Crear_Taller=async()=>{

      if(this.state.form.nombre_taller.length==0 || this.state.form.nombre_taller=='' || this.state.form.dir_taller.length==0 || this.state.form.dir_taller=='' || this.state.form.tel1_taller.length==0 || this.state.form.tel1_taller==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Workshops/Create",
  
          {
            name: this.state.form.nombre_taller,
            address: this.state.form.dir_taller,
            latitude: Latitud_taller,
            longitude: Longitud_taller,
            phone1: this.state.form.tel1_taller,
            phone2: this.state.form.tel2_taller,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' El taller: '+ this.state.form.nombre_taller + ' fué creada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-newtaller').modal('hide');
          this.Cargar_Taller();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
     }


     Cargar_Taller(){
      
      //this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Workshops/ListAll?numeroPagina=1")
      .then((response) => {
        console.log(response);
        this.setState({Taller:response.data.data})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
        if(this.state.Taller.length==0){
          //$('#google_maps').hide();   
         
        }else{
          //$('#google_maps').show();     
         
        }
  
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
  
    }


    Cargar_Estaciones(){
      
      //this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
      .then((response) => {
        console.log(response);
        this.setState({Estaciones:response.data.data})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
        if(this.state.Estaciones.length==0){
          $('#google_maps').hide();   
         
        }else{
          $('#google_maps').show();     
         
        }
  
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
  
    }


    Cargar_Bisicletas(){
      
      //this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Bikes/ListAll?numeroPagina=1")
      .then((response) => {
        console.log(response);
        this.setState({Bicicletas:response.data.data})
        this.setState({Pager_Bicicletas:response.data.pager.items})
        this.setState({pager_infobicicletas: response.data.pager.showData})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
        if(this.state.Estaciones.length==0){
          $('#google_maps').hide();   
         
        }else{
          $('#google_maps').show();     
         
        }
  
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
  
    }

    Cargar_BisicletasXpagina(page_bicicleta) {
      var page=page_bicicleta.pagina
      //alert(page)
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Bikes/ListAll?numeroPagina="+page)
      .then((response) => {
        console.log(response);
        this.setState({Bicicletas: response.data.data})
        this.setState({Pager_Bicicletas: response.data.pager.items})
        this.setState({pager_infobicicletas: response.data.pager.showData})

       
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

    Cargar_BisicletasXtexto(buscar_bicicleta) {
       
        //alert(page)
        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Bikes/ListAll?texto="+buscar_bicicleta)
        .then((response) => {
          console.log(response);
          this.setState({Bicicletas: response.data.data})
          this.setState({Pager_Bicicletas: response.data.pager.items})
          this.setState({pager_infobicicletas: response.data.pager.showData})
  
         
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }

    Cargar_BisicletasXestacion(id_estacion){
      
      //this.empty_page_hide();
      
    
    
      axios.get(ApiURL+"/api/Stations/GetStock/"+id_estacion)
      .then((response) => {
        console.log(response);
        this.setState({BicicletasXestacion:response.data.data.bikes})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()
  
      })
      .catch((error) => {
        console.log(error);
      })
  
    }


    Seleccionar_Estacion=(estacion)=>{

      $('#btn_edita_estacion').show(); 
      $('#btn_crear_estacion').hide(); 
      $('#modal-newpunto').modal('show');
     
      id_estacion=estacion.id
      Latitud=parseFloat(estacion.latitude)
      Longitud=parseFloat(estacion.longitude)
      //alert(id_estacion)      
      
      //alert(muni)
      
      this.setState({
        form:{
          nom_estacion:estacion.name,  
          dir_estacion:estacion.address,      
        }
      })      
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }

    Seleccionar_Estaciones_con_bicicletas=(estacion)=>{

      $("#estacion_destino").val($('#estacion_destino > option:first').val());   
      id_estacion_dest="0"
     
      id_estacion=estacion.id

      this.Cargar_BisicletasXestacion(id_estacion)
    
      //alert(id_estacion)      
      
      //alert(muni)
      
      this.setState({
        form:{
          estacion_origen:estacion.name     
        }
      }) 
      
      $('#modal-trasladar_bicicleta').modal('show');
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }


    Editar_Estacion=async()=>{

      if(this.state.form.nom_estacion.length==0 || this.state.form.nom_estacion=='' || this.state.form.dir_estacion.length==0 || this.state.form.dir_estacion==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Stations/Edit/"+id_estacion,
  
          {
            id:id_estacion,
            name: this.state.form.nom_estacion,
            address: this.state.form.dir_estacion,
            latitude:Latitud,
            longitude: Longitud,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' La estación '+ this.state.form.nom_estacion + ' fué editada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        
          $('#modal-newpunto').modal('hide');
          this.Cargar_Estaciones();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
    }
  
     Seleccionar_Bicicleta=(bici)=>{

      $('#btn_edita_bicicleta').show(); 
      $('#btn_crear_bicicleta').hide(); 
      $('#modal-newbicicleta').modal('show');
     
      id_bici=bici.id
     
      //alert(id_estacion)      
      
      //alert(muni)
      
      this.setState({
        form:{
          codigo_bicicleta:bici.code,  
          marco_bicicleta:bici.number 
        }
      })      
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }


    Editar_Bicicleta=async()=>{

      if(this.state.form.codigo_bicicleta.length==0 || this.state.form.codigo_bicicleta=='' || this.state.form.marco_bicicleta.length==0 || this.state.form.marco_bicicleta==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Bikes/Edit//"+id_bici,
  
          {
            id:id_bici,
            code: this.state.form.codigo_bicicleta,
            number: this.state.form.marco_bicicleta,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' La bicicleta '+ this.state.form.codigo_bicicleta + ' fué editada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        
          $('#modal-newbicicleta').modal('hide');
          this.Cargar_Bisicletas();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
    }


    Seleccionar_Taller=(taller)=>{

      $('#btn_edita_taller').show(); 
      $('#btn_crear_taller').hide(); 
      $('#modal-newtaller').modal('show');
     
      id_taller=taller.id
      Latitud_taller=parseFloat(taller.latitude)
      Longitud_taller=parseFloat(taller.longitude)
      //alert(id_estacion)      
      
      //alert(muni)
      
      this.setState({
        form:{
          nombre_taller:taller.name,
          dir_taller:taller.address,
          tel1_taller:taller.phone1,
          tel2_taller:taller.phone2      
        }
      })      
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }

    Editar_Taller=async()=>{

      if(this.state.form.nombre_taller.length==0 || this.state.form.nombre_taller=='' || this.state.form.dir_taller.length==0 || this.state.form.dir_taller=='' || this.state.form.tel1_taller.length==0 || this.state.form.tel1_taller==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Workshops/Edit/"+id_taller,
  
          {
            id:id_taller,
            name: this.state.form.nombre_taller,
            address: this.state.form.dir_taller,
            latitude: Latitud_taller,
            longitude: Longitud_taller,
            phone1: this.state.form.tel1_taller,
            phone2: this.state.form.tel2_taller,
           
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje+' El taller '+ this.state.form.nombre_taller + ' fué editada con éxito',
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        
          $('#modal-newpunto').modal('hide');
          this.Cargar_Estaciones();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
    }

    Trasladar_Bicicleta=async()=>{

      if(id_estacion=="0" || id_estacion_dest=="0" || id_bicicleta_tras=="0" ){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/StationTransfers/Transfer",
  
        {
          "stationOut": id_estacion,
          "stationIn": id_estacion_dest,
          "annotation": "traslado",
          "bikes": [
            {
              "bikeId": id_bicicleta_tras
            }
          ]
        })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: '¡Muy Bien!',
            html: mensaje,
            showConfirmButton: true,
            timer: 20000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

        
          $('#modal-newpunto').modal('hide');
          this.Cargar_Estaciones();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
    }



    render(){  

        const {form} = this.state;

    return(

    <div className="content-wrapper">

        <div className="col-12 col-sm-12">
      
                <div className="row ">
          
                    <div className="col-sm-12">
          
                         <br></br>
                         <div className="text-center" id="title"> <h5> <b> Puntos BISINÚ </b>  </h5> </div>
          
                    </div>      
          
                </div> 
                
                <div className="row justify-content-md-center">
    
            
                    <div  className="col-sm-5">
                          <button id="btn_crear" className="btn btn-default" onClick={()=>{this.handleModalnewpunto()}}>Crear estacion
                             <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                             </svg> 
                          </button> 
            
                   
                          <button id="btn_crear" className="btn btn-default" onClick={()=>{this.handleModalnewbicicleta()}}>Crear bicicleta
                             <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                             </svg> 
                          </button> 
            
                   
                          <button id="btn_crear" className="btn btn-default" onClick={()=>{this.handleModalnewtaller()}}>Crear taller
                             <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                             <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                             </svg> 
                          </button> 
            
                    </div>
          
                </div>             
          
                <div className="card card-primary card-outline card-tabs"> 

                        <div className="card-header p-0 pt-1 border-bottom-0">
                          
                            <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                                <li className="nav-item">
                                  <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true" > <span className="badge badge-danger"></span> Estaciones  </a>
                                </li> 
                                <li className="nav-item">
                                  <a className="nav-link" id="custom-tabs-three-proceso-tab" data-toggle="pill" href="#custom-tabs-three-proceso" role="tab" aria-controls="custom-tabs-three-proceso" aria-selected="false"  onClick={()=>{this.Cargar_Bisicletas()}} > <span className="badge badge-danger"></span> Bicicletas  </a>
                                </li> 
                                <li className="nav-item">
                                  <a className="nav-link" id="custom-tabs-three-taller-tab" data-toggle="pill" href="#custom-tabs-three-taller" role="tab" aria-controls="custom-tabs-three-taller" aria-selected="false" onClick={()=>{this.Cargar_Taller()}} > <span className="badge badge-danger"></span> Talleres  </a>
                                </li>                   
                                             
                            </ul>
                        
                        </div>

                                  
                            
                        <div className="card-body"> 

                        <div className="tab-content" id="custom-tabs-three-tabContent">

                            <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">

      
                                <div className="d-flex justify-content-center">
                
                                   {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />} 
                          
                                </div>                   
                                
                                <div className="card table-responsive" id="tabla1">
                        
                        
                                        <table id="tabla_convenios" className="table table-bordered table-striped">
                                          <thead>
                                            <tr>
                                              <th>ID</th>
                                              <th>Estación</th>         
                                              <th>Dirección</th>
                                              <th>Estado</th>
                                              <th>Editar</th>
                                              <th>Trasladar</th>
                                            </tr>
                                          </thead>
                                          <tbody>


                                          {this.state.Estaciones.map(estacion=>{
                                              return(      
                                              
                                              <tr>          
                                                <td>{estacion.id}</td>    
                                                <td>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                <td>{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                <td>{estacion.state}</td>                                          
                                                <td onClick={()=>{this.Seleccionar_Estacion(estacion)}}> <Editar  /> </td> 
                                                <td onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(estacion)}}> <Trasladar  /> </td>   
                                              </tr>
                                      
                                                )
                                               }
                                            )}                                         
                                                        
                                          </tbody>
                                        </table>
                        
                                </div>
                                
                                <div className="d-flex justify-content-center">                              
                                     {/* <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>
                               

                            </div>

                            <div className="tab-pane fade" id="custom-tabs-three-proceso" role="tabpanel" aria-labelledby="custom-tabs-three-proceso-tab">                                             
                                                                          
                                <div class="d-flex justify-content-center">
                                    
                                   {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                            
                                </div>
                                        
                                <div className="card table-responsive" id="tabla2">  

                                        <div className="row">
                                            
                                            <div className="col-sm-3">
                                                  <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                    <div className="input-group-prepend">
                                                      <div className="input-group-text"><Buscar/></div>
                                                    </div>
                                                    <input type="search" className="form-control" placeholder="Buscar" name="buscar_bicicleta" onChange={this.BuscarBicicleta}/>
                                                  </div>                                
                                            </div>
                                            
                                        </div>                                   
                                   
                        
                                        <table className="table table-bordered table-striped table-hover">
                                            <thead>
                                              <tr>
                                                <th>ID</th>
                                                <th>Código</th>
                                                <th>Número de marco</th>
                                                <th>Estado</th>
                                                <th>Editar</th>      
                                              
                                              </tr>
                                            </thead>
                                            <tbody>                                              
                                           
                                            {this.state.Bicicletas.map(bici=>{
                                              return(      
                                              
                                              <tr>          
                                                <td>{bici.id}</td>    
                                                <td>{bici.code}</td>
                                                <td>{bici.number}</td>
                                                <td>{bici.state}</td>                                          
                                                <td onClick={()=>{this.Seleccionar_Bicicleta(bici)}}> <Editar  /> 
                                                </td>
                                              </tr>
                                      
                                                )
                                               }
                                            )} 
                                      
                                            </tbody>
                                        </table>

                                        <span>{this.state.pager_infobicicletas}</span>
                                    
                                        <nav className="nav">
                                        
                                            <ul className="pagination">
                                                {this.state.Pager_Bicicletas.map(page_bicicleta=>{
                                                
                                                  var activo=page_bicicleta.activo
                                                  if(activo==true){
                                                    activo='page-item active'
                                                  }else{
                                                    activo='page-item'
                                                  }
                                                // alert(activo)
                                                  
                                                return(                          
                                                          
                                                        <li className={activo}>
                                                          <a className="nav-link" onClick={()=>{this.Cargar_BisicletasXpagina(page_bicicleta)}}>{page_bicicleta.texto}</a>
                                                        </li>                                            
                                                  )
                                                  }
                                                )
                                                }
                                            </ul>
                                                  
                                        </nav>
                                                                                               
                                                
                                 
                                </div>
                                <div className="d-flex justify-content-center">                              
                                   {/* <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>
                               
                                       
                            </div> 

                            <div className="tab-pane fade" id="custom-tabs-three-taller" role="tabpanel" aria-labelledby="custom-tabs-three-taller-tab">                                             
                                                                          
                                <div class="d-flex justify-content-center">
                                    
                                   {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                            
                                </div>
                                        
                                <div className="card table-responsive" id="tabla3">                                    
                                   
                        
                                        <table className="table table-bordered table-striped table-hover">
                                            <thead>
                                              <tr>
                                                <th>ID</th>
                                                <th>Taller</th>
                                                <th>Dirección</th>
                                                <th>Teléfono</th> 
                                                <th>Editar</th>   
                                                                                             
                                              </tr>
                                            </thead>
                                            <tbody>                                              
                                           
                                            {this.state.Taller.map(taller=>{
                                              return(      
                                              
                                              <tr>          
                                                <td>{taller.id}</td>    
                                                <td>{(taller.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                <td>{(taller.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                <td>{taller.phone1}</td>                                          
                                                <td onClick={()=>{this.Seleccionar_Taller(taller)}}> <Editar  /></td>
                                                
                                              </tr>
                                      
                                                )
                                               }
                                            )} 
                                      
                                            </tbody>
                                        </table>
                                                                                               
                                                
                                 
                                </div>
                                <div className="d-flex justify-content-center">                              
                                   {/* <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>
                               
                                       
                            </div> 

                        </div>
                            
                        </div>
                                
                </div>
      
        
        </div>
      
      
        <div className="modal fade" id="modal-newpunto" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"><b>Crear Estación</b></h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
      
                    <div className="row">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Nombre estación:</label>
                                <input type="text"  maxLength="50" className="form-control"  placeholder="nombre estación" name="nom_estacion" autoComplete="nope" value={form.nom_estacion} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Dirección:</label>
                                <input type="text" maxLength="50" className="form-control camponum" placeholder="dirección estación" autoComplete="nope" name="dir_estacion" value={form.dir_estacion} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
            
                    </div>  

                    <hr></hr>
      
                    <div className="row justify-content-center">
                
                      <label id="importante">*Importante: Ubica el marcado <img  src={Marcador} alt="BISINU" height="30" className="brand-image img-rounded" /> en la dirección aproximada de la estación</label>
                      <div className="col-md-12">                    
                      
                      <LoadScript
                          googleMapsApiKey={mapURL}
                          libraries={['places']}
                      >
                      <GoogleMap
                        id="marker-example"
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                        options={{
                          zoomControl:false,
                          streetViewControl:false,
                          mapTypeControl:false,
                          fullscreenControl:false
                        }}                        
                      >

                     <Marker                  
                     onLoad={onLoad}
                     position={{
                      lat: Latitud,
                      lng: Longitud
                    }}
                     options={{
                      label:{
                        text:"Ubica la estación",
                        className:"map-marker"
                      },
                      draggable:true
                     }}
                     onDragEnd={(e) => this.onMarkerDragEnd(e.latLng)}
                     />
   
                      </GoogleMap>
                      </LoadScript>
                          
                       
                      </div>                 
                 
                    </div>
     
                   
              </div>
      
              <div className="modal-footer justify-content-between">        
                <button type="button" id="btn_crear_estacion" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_Estacion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Guardar</button>
                <button type="button" id="btn_edita_estacion" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Editar_Estacion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Editar</button>
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
       
        <div className="modal fade" id="modal-newbicicleta" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"><b>Crear Bicicleta</b></h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
      
                    <div className="row">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Código bicicleta:</label>
                                <input type="number"  maxLength="10" className="form-control"  placeholder="código" name="codigo_bicicleta" autoComplete="nope" value={form.codigo_bicicleta} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Número de marco:</label>
                                <input type="text" maxLength="50" className="form-control camponum" placeholder="número de marco" autoComplete="nope" name="marco_bicicleta" value={form.marco_bicicleta} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
            
                    </div>  

                  
                   
              </div>
      
              <div className="modal-footer justify-content-between">        
                <button type="button" id="btn_crear_bicicleta" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_Bicicleta()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Guardar</button>
                <button type="button" id="btn_edita_bicicleta" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Editar_Bicicleta()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Editar</button>
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>      

        <div className="modal fade" id="modal-newtaller" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"><b>Crear Taller</b></h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
      
                    <div className="row">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Nombre del taller:</label>
                                <input type="text"  maxLength="50" className="form-control"  placeholder="nombre" name="nombre_taller" autoComplete="nope" value={form.nombre_taller} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Dirección:</label>
                                <input type="text" maxLength="50" className="form-control camponum" placeholder="dirección" autoComplete="nope" name="dir_taller" value={form.dir_taller} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="row">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Telefono 1:</label>
                                <input type="number"  maxLength="10" className="form-control"  placeholder="telefono 1" name="tel1_taller" autoComplete="nope" value={form.tel1_taller} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <label >Telefono 2:</label>
                                <input type="number" maxLength="10" className="form-control camponum" placeholder="telefono 2" autoComplete="nope" name="tel2_taller" value={form.tel2_taller} onChange={this.handleChange} />                       
                            </div>
      
                        </div>
            
                    </div> 

                    <hr></hr>
      
                    <div className="row justify-content-center">
                
                      <label id="importante">*Importante: Ubica el marcado <img  src={Marcador} alt="BISINU" height="30" className="brand-image img-rounded" /> en la dirección aproximada del taller</label>
                      <div className="col-md-12">                    
                      
                      <LoadScript
                          googleMapsApiKey={mapURL}
                          libraries={['places']}
                      >
                      <GoogleMap
                        id="marker-example"
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                        options={{
                          zoomControl:false,
                          streetViewControl:false,
                          mapTypeControl:false,
                          fullscreenControl:false
                        }}                        
                      >                    

                      
                     <Marker                   
                     onLoad={onLoad}
                     position={{
                      lat: Latitud_taller,
                      lng: Longitud_taller
                    }}
                     options={{
                      label:{
                        text:"Ubica el taller",
                        className:"map-marker"
                      },
                      draggable:true
                     }}
                     onDragEnd={(e) => this.onMarkerDragEnd_taller(e.latLng)}
                     />
   
                      </GoogleMap>
                      </LoadScript>
                          
                       
                      </div>                 
                 
                    </div>                  
                   
              </div>
      
              <div className="modal-footer justify-content-between">        
                <button type="button" id="btn_crear_taller" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_Taller()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Guardar</button>
                <button type="button" id="btn_edita_taller" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Editar_Taller()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Editar</button>
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>

        <div className="modal fade" id="modal-trasladar_bicicleta" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"><b>Trasladar Bicicleta</b></h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
      
                    <div className="row">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Estación origen:</label>
                                <input type="text" className="form-control" name="estacion_origen" autoComplete="nope" value={form.estacion_origen} readOnly/>                       
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Estacíon destino:</label>
                                <select onChange={this.Get_id_estaciondest}  className="form-control" id="estacion_destino" >
                                    <option  value="0"> Seleccione...</option> ,
                                    {this.state.Estaciones.map(item=>                              
                                    <option key={item.id} value={item.id}> {item.name}</option>  
                                    )}
                                            
                                </select>
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="row">

                      
      
                        <div className="col-md-12">
      
                            <div className="form-group">                    
                                <b id="asterisco">* </b><label >Bicicleta:</label>
                                <select onChange={this.Get_id_bicicleta}  className="form-control" id="estacion_destino" >
                                    <option  value="0"> Seleccione...</option> ,
                                    {this.state.BicicletasXestacion.map(item=>                              
                                    <option key={item.id} value={item.id}> {item.code}-{item.number}</option>  
                                    )}
                                            
                                </select>
                            </div>
      
                        </div>
            
                    </div> 

                  
                   
              </div>
      
              <div className="modal-footer justify-content-between">        
                <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Trasladar_Bicicleta()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Trasladar </button>
                <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      
      
      
    </div>

    )

    }

}


export default Puntos_Bisinu;       