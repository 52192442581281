import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import { Document, Page } from 'react-pdf';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu from '../tools/Imagenes/LogoA.png'
import Buscar from '../components/Buscar';
import Slider1 from '../tools/Imagenes/slider1.jpg'
import Slider2 from '../tools/Imagenes/slider2.jpg'
import Slider3 from '../tools/Imagenes/slider3.jpg'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion=0;
var id_estacion_dev="0";
var id_solicitud=0
var id_bicicleta=0
var id_bicicleta_prest="0"
var buscar_solicitud=''
var id_user=0
var id_prestamo=0
var tipo_id='0';
var btn_estado_prestamos=""
var nombre_estacion=""
var documento_user2=""


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}

const onDocumentLoadSuccess = ({ numPages }) => {
  console.log('Número de páginas:', numPages);
};

class Home_anfitrion extends Component {

  

  constructor()
    {
     super()
      this.state={
        Solicitudes:[],
        Prestamos:[],
        Estaciones:[] ,
        Estaciones_dev:[],
        Tipo_id:[] ,  
        BicicletasXestacion:[],
        loading: false,
        loading_button: true,
        loading_button_prestamo: true,
        active_button: true,
        active_button_prestamo: false,
       
        form:{   
          nom_estacion:'',  
          fecha_prestamo:moment().format("DD/MM/YYYY"),
          hora_prestamo:moment().format("hh:mm"),
          obser:'',
          obser_prestamo:'',
          num_id_user:'',
          obser_dev:'',
          num_bicicleta:'',
          documento_user:"",
          nombre_usuario:"",
            
          
          }     
      }
    }

    

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  Get_id_bicicleta = (ib) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ib.target.value)
    id_bicicleta=(ib.target.value)     
  }

  Get_id_bicicleta_prest = (ibp) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ibp.target.value)
    id_bicicleta_prest=(ibp.target.value)     
  }

  GettipoID = (id) =>{
    //this.setState({tipoid:id.target.value})
    console.log(id.target.value)
    tipo_id=(id.target.value) 
    this.setState({ active_button_prestamo: this.state.active_button_prestamo = false })    
  }

Get_id_estacion = (ie) =>{
  //this.setState({tipoid:id.target.value})
  console.log(ie.target.value)
  id_estacion_dev=(ie.target.value)     
}

Get_id_estacion_prest = (iep) =>{
  //this.setState({tipoid:id.target.value})
  console.log(iep.target.value)
  id_estacion=(iep.target.value)  
  this.Cargar_BisicletasXestacion(id_estacion)   
}

Get_nombre_estacion = (ne) =>{
  //this.setState({tipoid:id.target.value})
  console.log(ne.target.value)
  nombre_estacion=(ne.target.value)    
  this.searchDatasolicitud(nombre_estacion) 
}

  Buscar_solicitud = (bs) =>{
  
    console.log(bs.target.value)
    buscar_solicitud=(bs.target.value)
    this.searchDatasolicitud(buscar_solicitud)
   
  }

  componentDidMount(){  
    $('#con_prestamo').hide();
    $('#sin_prestamo').hide();
    $('#datos_soli').hide();     
    $('#google_maps').hide();
    this.Listar_Solicitudes();
    this.Cargar_tipo_id();
    this.Cargar_Estaciones();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_estaciones()
  { 
   
    $('#modal_estaciones').modal('show');
  }


  handleModal_solicitud_prestamo()
  { 
   
    $('#modal_solicitud_prestamo').modal('show');
  }

  handleModal_prestamos()
  {
    $("#id_estacion_prestamo").val($('#id_estacion_prestamo > option:first').val()); 
    $("#id_bicicleta_prestamo").val($('#id_bicicleta_prestamo > option:first').val()); 
    $('#modal_prestamos').modal('show');

    id_estacion=0
    id_bicicleta_prest="0"
  }

  handleModal_ver_documento()
  {   
    $('#modal_ver_documento').modal('show');
  }

  handleModal_ver_documento2()
  {   
    $('#modal_ver_documento_sinsoli').modal('show');
  }

  Limpiar_lista_bicicletas(){
    this.setState({BicicletasXestacion:[]})
    this.setState({
      form:{   
        num_id_user:this.state.form.num_id_user,  
        nom_user: this.state.form.nom_user,      
        obser_dev:"",
        obser_prestamo:""
      }
    })
  }


  Cargar_tipo_id=async()=>{    
    
    await axios.get(ApiURL+"/api/IdentificationTypes/ListAll")
      .then((response) => {
        //console.log(response);
        this.setState({Tipo_id: response.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }


  Listar_Solicitudes(){
      
    //this.empty_page_hide();
    this.setState({BicicletasXestacion:[]})
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/BikeRequests/ListAll?numeroPagina=1")
    .then((response) => {
      //console.log(response);
      this.setState({Solicitudes:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
     

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  searchDatasolicitud(buscar_solicitud) {

    this.setState({ loading: this.state.loading = false })
    
    axios.get(ApiURL+"/api/BikeRequests/ListAll?numeroPagina=1&texto="+buscar_solicitud)
  .then((response) => {
    //console.log(response);

    this.setState({Solicitudes:response.data.data})    
   
    this.setState({ loading: this.state.loading = true })

    })
    .catch((error) => {
      console.log(error);
    })
  
  }

  Buscar_usuario=async()=>{


    if(tipo_id=="0" || this.state.form.num_id_user.length==0 || this.state.form.num_id_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.get(ApiURL+"/api/Users/GetUser/"+tipo_id+"/"+this.state.form.num_id_user)  
      .then(response=>{
        console.log(response.data.data)
        var  mensaje  = response.data.message;
        id_user=response.data.data.user.id
        var length_documents=response.data.data.user.document.length
        documento_user2=response.data.data.user.document[length_documents-1].urlDocument
        this.setState({
          form:{   
            num_id_user:this.state.form.num_id_user,  
            nom_user: response.data.data.user.name1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))+ " "+  response.data.data.user.lastName1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
            obser_dev:"",
            obser_prestamo:""
          }
        }) 

      //alert(response.data.data.user.document[length_documents-1].urlDocument)
        
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: 'Usuario encontrado con éxito',
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
        this.setState({ active_button_prestamo: this.state.active_button_prestamo = true })
        
        //this.componentDidMount();
        this.Listar_Prestamos(id_user)

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          
          this.setState({
            form:{   
              num_id_user:this.state.form.num_id_user,  
              nom_user: "",      
              obser_dev:"",
              obser_prestamo:""
            }
          })
          $('#sin_prestamo').hide();
          $('#con_prestamo').hide();
          this.setState({Prestamos:[]})

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          $('#sin_prestamo').hide();
          $('#con_prestamo').hide();

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          $('#sin_prestamo').hide();
          $('#con_prestamo').hide();
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
      $('#sin_prestamo').hide();
      $('#con_prestamo').hide();
    })

    }    

  }

  Listar_Prestamos(id_user){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByUser/"+id_user)
    .then((response) => {
      //console.log(response);
      this.setState({Prestamos:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
     
      $('#sin_prestamo').hide();
      $('#con_prestamo').show();
      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
      $('#sin_prestamo').show();
      $('#con_prestamo').hide();
      this.setState({Prestamos:[]})
     
    })

  }

  Cargar_Estaciones(){
      
    //this.empty_page_hide();
  
  
    axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
    .then((response) => {
      //console.log(response);
      this.setState({Estaciones_dev:response.data.data.filter(item => item.id !== 1)})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

    })
    .catch((error) => {
      console.log(error);
    })

  }


  Cargar_Estaciones_stock(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/GetStationsStock")
    .then((response) => {
      //console.log(response);
      this.setState({Estaciones:response.data.data.filter(item => item.id !== 1)})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }


  Seleccionar_solicitud=(soli)=>{

    $("#id_bicicleta").val($('#id_bicicleta > option:first').val());
   
    id_solicitud=soli.id
    id_estacion=soli.station.id
    id_bicicleta=0

    this.Cargar_BisicletasXestacion(id_estacion)
  
    //alert(id_estacion)     
    
    //alert(muni)
    
    this.setState({
      form:{
        nom_estacion:soli.station.name.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
        fecha_prestamo:moment().format("DD/MM/YYYY"),
        hora_prestamo:moment().format("hh:mm"),
        obser:'',
        documento_user:soli.user.documents[0].urlDocument,
        nombre_usuario:soli.user.name1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) +" "+ soli.user.lastName1.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      }
    })  
    
    $('#modal_solicitud_prestamo').modal('show');
    //var  dato  = servicio.id;
    //alert(soli.user.documents[0].urlDocument);
    //alert(this.state.form.hora_prestamo);
  }
  

  Realizar_devolucion=async()=>{

    if(id_prestamo==0 || id_estacion_dev=="0" ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/StationTransfers/ReturnBike",

        {
          lendingBikeId: id_prestamo,
          stationId:id_estacion_dev,
          annotation: this.state.form.obser_dev,
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
        $('#modal_devolucion').modal('hide');
        this.Buscar_usuario();
        this.Listar_Prestamos(id_user);

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }


  Seleccionar_prestamo=(prestamo)=>{

    $("#id_estacion_dev").val($('#id_estacion_dev > option:first').val());
    id_estacion_dev="0"
   
    id_prestamo=prestamo.id
   
  
    this.setState({
      form:{
        num_bicicleta:prestamo.bike.code +"-"+ prestamo.bike.number ,
        obser_dev:''   
      }
    })
    //alert(num_bicicleta) 
    this.Cargar_Estaciones()
    
    $('#modal_devolucion').modal('show');
    
  }


  Cargar_BisicletasXestacion(id_estacion){
      
    //this.empty_page_hide();  
  
  
    axios.get(ApiURL+"/api/Stations/GetStock/"+id_estacion)
    .then((response) => {
      //console.log(response);
      this.setState({BicicletasXestacion:response.data.data.bikes})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

    })
    .catch((error) => {
      console.log(error);
    })

  }

  Realizar_prestamo=async()=>{

    if(id_solicitud==0 || id_estacion==0 || id_bicicleta==0 ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/StationTransfers/LendingBike",

        {
          bikeRequestId: id_solicitud,
          bikeId:id_bicicleta,
          stationId:id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        //this.componentDidMount();
        $('#modal_solicitud_prestamo').modal('hide');
        this.Listar_Solicitudes();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }    

  }

  Realizar_prestamo_sin_solicitud=async()=>{

    if( id_estacion==0 || id_bicicleta_prest=="0" || id_user==0 ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados para el préstamo',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = false })
      this.setState({ active_button_prestamo: this.state.active_button_prestamo = false })

      await axios.post(ApiURL+"/api/StationTransfers/RequestFull",

        {
          stationId: id_estacion,
          bikeId:id_bicicleta_prest,
          userId:id_user,
          annotation: this.state.form.obser_prestamo,
         
        })  
      .then(response=>{
        //console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = true })
        this.setState({ active_button_prestamo: this.state.active_button_prestamo = false })

        //this.componentDidMount();
        $('#modal_prestamos').modal('hide');
        this.Listar_Solicitudes();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = true })
          this.setState({ active_button_prestamo: this.state.active_button_prestamo = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = true })
          this.setState({ active_button_prestamo: this.state.active_button_prestamo = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = true })
          this.setState({ active_button_prestamo: this.state.active_button_prestamo = true })
      }
      //console.log(error.config);
      this.setState({ loading_button_prestamo: this.state.loading_button_prestamo = true })
      this.setState({ active_button_prestamo: this.state.active_button_prestamo = true })
    })

    }    

  }

  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoBici} alt="BISINU" height="70" className="brand-image img-rounded" />
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

                <div className="row justify-content-center">
                
                  <div className="col-md-10">                    
                  
                      <AwesomeSlider cssModule={Estilo}>
                        <div data-src={Slider1} />
                        <div data-src={Slider2} />
                        <div data-src={Slider3} />
                      </AwesomeSlider>
                      
                   
                  </div>                 
                 
                </div>

                <br></br>
                <hr></hr>

               
                <div className="row justify-content-center">

                   
                
                  <div className="col-md-10" >                    
                  
                    <div className="card card-primary card-outline card-tabs"> 

                      <div className="card-header p-0 pt-1 border-bottom-0">
                        
                          <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                              <li className="nav-item">
                                <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="true"v onClick={()=>{this.Listar_Solicitudes()}} > <span className="badge badge-danger"></span> Solicitudes  </a>
                              </li> 
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-proceso-tab" data-toggle="pill" href="#custom-tabs-three-proceso" role="tab" aria-controls="custom-tabs-three-proceso" aria-selected="false"  > <span className="badge badge-danger"></span> Devoluciones-Préstamos  </a>
                              </li> 
                                                               
                          </ul>
                      
                      </div>                      
                                
                          
                      <div className="card-body"> 
                      
                        <div className="tab-content" id="custom-tabs-three-tabContent">
                        
                            <div className="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                        
                        
                                <div className="d-flex justify-content-center">
                        
                                   {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />} 
                          
                                </div>

                                <div className="row">
                                      
                                    {/* <div className="col-md-6">
                                          <div className="input-group input-group-sm mb-3 mr-sm-2">
                                             <div className="input-group-prepend">
                                               <div className="input-group-text"><Buscar/></div>
                                             </div>
                                             <input type="search" className="form-control" placeholder="Buscar" onChange={this.Buscar_solicitud}/>
                                          </div>                                
                                    </div> */}

                                    <div className="col-md-6">
      
                                        <div className="form-group">                    
                                            <label >Estación:</label>
                                            <select onChange={this.Get_nombre_estacion}  className="form-control" id="id_estacion_dev" >
                                                <option  value="0"> Todas</option> ,
                                                {this.state.Estaciones_dev.map(item=>                              
                                                <option key={item.id} value={item.name}> {item.name}</option>  
                                                )}
                                                        
                                            </select>
                                        </div>
                  
                                    </div>
                                    
                                </div>                   
                                
                                {this.state.Solicitudes.map(soli=>{
                                  
                                return(    
                                                    
                                                  
                                <div className="card" onClick={()=>{this.Seleccionar_solicitud(soli)}}>
      
                                    <div className="d-flex justify-content-center">                                               
                                        
                                        <div className="col-md-2" align="left">
                                          <img src={LogoBisinu} width="70px" id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                          
                                        </div>
                                        <div className="col-md-6">
                                         
                                            <h6 className="card-text" id="imgcard_estacion" align="left"><b>{(soli.user.name1).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  {(soli.user.lastName1).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b>
                                            </h6>
                                            <h6 className="card-text" id="imgcard_estacion" align="left"><b><Moment format="YYYY/MM/DD HH:mm"> 
                                            {soli.date} 
                                            </Moment></b>
                                            </h6>
                                            <p className="card-text" id="textcard_estacion" align="left">{(soli.state).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                            
                                                                       
                                        </div>
                                        <div className="col-md-4">                    
      
                                           
                                            <h6 className="card-text" id="imgcard_estacion" align="left"><b>Estación: {soli.station.name}</b> </h6>
                                            <p className="card-text" id="textcard_estacion"> Hace: <Moment fromNow ago locale='es'> 
                                            {soli.date} 
                                            </Moment></p>
                                            
                                                                       
                                        </div>
      
                                    </div>  
                            
                                </div>  
      
                                 )
                                }
                                )}
                                
                                <div className="d-flex justify-content-center">                              
                                     {/* <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>

                                <br></br>
                                <div className="row">
                                  <div className="col-12">
                                  <a href="/reportar_taller"> <button type="button" id="btn_registrar" className="btn btn-primary btn-block" >Taller Bisinú</button> </a>
                                  </div>
                                 
                                </div>
                               
                        
                            </div>
                        
                            <div className="tab-pane fade" id="custom-tabs-three-proceso" role="tabpanel" aria-labelledby="custom-tabs-three-proceso-tab">                                             

                                <div className="row">                               
                                
                                    <div className="col-md-4">
        
                                        <div className="form-group">                    
                                            <label >Tipo de identificación</label>
                                            <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid"  >
                                    
                                                <option  value="0"> Seleccione...</option> ,
                                                {this.state.Tipo_id.map(item=>                              
                                                <option key={item.id} value={item.id}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                                )}
                                                      
                                            </select>
                                        </div>
                                    
                                    </div>
                                    
                                    <div className="col-md-4">
                                    
                                        <div className="form-group">                    
                                            <label >Número de identificación</label>
                                            <input type="text" maxLength="12" className="form-control" autoComplete='nope' id="num_id_user" name="num_id_user" onChange={this.handleChange} />                       
                                        </div>
                                    
                                    </div>

                                    <div className="col-md-4">
                          
                                        <div className="form-group">                    
                                            <label >Nombre usuario</label>
                                            <input type="text" className="form-control"  id="nom_user" name="nom_user" value={form.nom_user} readOnly />                       
                                        </div>
                                    
                                    </div>

                                </div>

                                <br></br>
                                <div className="row">
                                  <div className="col-6">
                                    <button type="button" id="btn_registrar" className="btn btn-primary btn-block" disabled={!this.state.active_button} onClick={()=>{this.Buscar_usuario()}} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Buscar usuario</button>
                                  </div>
                                  <div className="col-6">
                                    <button type="button" id="btn_registrar" className="btn btn-primary btn-block" disabled={!this.state.active_button_prestamo} onClick={()=>{this.handleModal_prestamos();this.Limpiar_lista_bicicletas()}} >  Realizar préstamo</button>
                                  </div>
                                 
                                </div> 

                                <hr></hr>                                 

                                <div className="d-flex justify-content-center">
                                    
                                   <h5 id="sin_prestamo">Usuario sin prestamos registrados</h5>
                            
                                </div>

                                <div className="d-flex justify-content-center">
                                    
                                   <h5 id="con_prestamo">Historial de préstamos del usuario</h5>
                            
                                </div>

                                {this.state.Prestamos.map(prestamo=>{
                              
                                var estado_prestamos=prestamo.state
                               
                                if(estado_prestamos=="activo"){
                                  btn_estado_prestamos=<button type="button" id="imgcard_estacion" className="btn btn-primary btn-sm" onClick={()=>{this.Seleccionar_prestamo(prestamo)}}>Devolver</button>
                                }else{
    
                                  if(estado_prestamos=="devuelto"){
    
                                    btn_estado_prestamos=<button type="button" id="imgcard_estacion" className="btn btn-success btn-sm">Devuelto</button>
    
                                  }
                                  
                                }
    
                                return(      
    
    
                                <div className="card">
    
                                    <div className="d-flex justify-content-center">                                               
    
                                        <div className="col-md-2" align="left">
                                          <img src={LogoBisinu} width="70px" id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
    
                                        </div>
                                        <div className="col-md-6">
    
    
                                            <h6 className="card-text" id="imgcard_estacion"  align="left"><b>{prestamo.station.name}</b>
                                            </h6>
                                            <p className="card-text" id="imgcard_estacion" align="left"> <Moment format="YYYY/MM/DD HH:mm"> 
                                            {prestamo.date} 
                                            </Moment>
                                            </p>
                                            <p className="card-text" id="imgcard_estacion" align="left"> Hace: <Moment fromNow ago locale='es'> 
                                            {prestamo.date} 
                                            </Moment>
                                            </p>
    
    
                                        </div>
                                        <div className="col-md-4">                     
    
                                            <h6 className="card-text" id="imgcard_estacion" align="left"><b> {prestamo.bike.code} - {prestamo.bike.number}</b> </h6>
                                            {btn_estado_prestamos}
                                
    
                                        </div>
    
                                    </div>  
                                
                                </div>  
    
                                 )
                                }
                                )}
                                        
                                
                                <div className="d-flex justify-content-center">                              
                                   {/* <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                              */}
                                </div>

                                                             
                                       
                            </div> 
                        
                        </div>
                          
                      </div>
                              
                    </div>
                   
                  </div>                 
                 
                </div>

              </div>

              <div className="modal fade" id="modal_estaciones" >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        
        
                        <div className="modal-body"> 

                        {this.state.Estaciones.map(estacion=>{
                          return(      
                                              
                                            
                          <div className="card" onClick={()=>{this.Seleccionar_Estacion(estacion);this.handleModal_solicitud_prestamo()}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left">{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{estacion.stock + " Disponibles"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                           )
                          }
                          )}         
                      
                        </div>            
        
                      </div>  
                    </div>
              </div>


              <div className="modal fade" id="modal_solicitud_prestamo" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Solicitud de prestamo - {form.nom_estacion}</h5>
                      
                    </div>
                    <div className="modal-body">

                      <div className="row" id="datos_soli">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                            
                              <input type="date" className="form-control"  name="fecha_prestamo" value={form.fecha_prestamo} min={form.fecha_prestamo} onChange={this.handleChange} />               
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                              <label >Fin:</label>
                              <input type="time" className="form-control" name="hora_prestamo" value={form.hora_prestamo} onChange={this.handleChange} />                    
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco"> Usuario: </b>{form.nombre_usuario} <a href="#" onClick={()=>{this.handleModal_ver_documento()}}>Ver documento</a>
                           
                        </div>
  
                    </div>

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Bicicleta:</label>
                            <select onChange={this.Get_id_bicicleta}  className="form-control" id="id_bicicleta" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.BicicletasXestacion.map(item=>                              
                                <option key={item.id} value={item.id}> {item.code}-{item.number}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">

                        <div className="form-group">                    
                                <label >Observación:</label>                           
                                <textarea class="form-control" maxLength="100"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                
                        </div>
                        
                    </div>
                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Realizar_prestamo()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Entregar Bisinú </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modal_prestamos" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Realizar prestamo directo</h6>
                      
                    </div>
                    <div className="modal-body">

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco"> Usuario: </b>{form.nom_user} <a href="#" onClick={()=>{this.handleModal_ver_documento2()}}>Ver documento</a>
                           
                        </div>
  
                    </div>                     

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Estación entrega:</label>
                            <select onChange={this.Get_id_estacion_prest}  className="form-control" id="id_estacion_prestamo" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.Estaciones_dev.map(item=>                              
                                <option key={item.id} value={item.id}> {item.name}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Bicicleta:</label>
                            <select onChange={this.Get_id_bicicleta_prest}  className="form-control" id="id_bicicleta_prestamo" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.BicicletasXestacion.map(item=>                              
                                <option key={item.id} value={item.id}> {item.code}-{item.number}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">

                        <div className="form-group">                    
                                <label >Observación:</label>                           
                                <textarea class="form-control" maxLength="100"  rows="2" id="obser_prestamo" name="obser_prestamo" value={form.obser_prestamo} onChange={this.handleChange}  ></textarea>
                                
                        </div>
                        
                    </div>
                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button_prestamo} onClick={()=> this.Realizar_prestamo_sin_solicitud()} > {this.state.loading_button_prestamo?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Entregar Bisinú </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modal_ver_documento" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Documento - {form.nombre_usuario}</h5>
                      
                    </div>
                    <div className="modal-body">
                    

                    <div className="col-md-12">
      
                        <h3 className="text-center">Documento</h3> 
                    
                        <img src={form.documento_user} className="rounded mx-auto d-block" width="300px" alt="User Document..." />
                  
  
                    </div>

                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modal_ver_documento_sinsoli" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Documento - {form.nom_user}</h5>
                      
                    </div>
                    <div className="modal-body">
                    

                    <div className="col-md-12">
      
                        <h3 className="text-center">Documento</h3> 
                    
                        <img src={documento_user2} className="rounded mx-auto d-block" width="300px" alt="User Document..." />
                  
  
                    </div>

                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modal_devolucion" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Devolución - Bicicleta # {form.num_bicicleta}</h6>
                      
                    </div>
                    <div className="modal-body">
                     

                    <div className="col-md-12">
      
                        <div className="form-group">                    
                            <b id="asterisco">* </b><label >Estación recibe:</label>
                            <select onChange={this.Get_id_estacion}  className="form-control" id="id_estacion_dev" >
                                <option  value="0"> Seleccione...</option> ,
                                {this.state.Estaciones_dev.map(item=>                              
                                <option key={item.id} value={item.id}> {item.name}</option>  
                                )}
                                        
                            </select>
                        </div>
  
                    </div>

                    <div className="col-md-12">

                        <div className="form-group">                    
                                <label >Observación:</label>                           
                                <textarea class="form-control" maxLength="100"  rows="2" id="obser_dev" name="obser_dev" onChange={this.handleChange}  ></textarea>
                                
                        </div>
                        
                    </div>
                    
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Realizar_devolucion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Devolver Bisinú </button>
                    </div>
                  </div>
                </div>
              </div>

 
            
        </div>

        

    
    )

  }
}

export default Home_anfitrion;   