import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Login.css';

import LogoBici from '../tools/Imagenes/BISINU.png'

import $ from 'jquery'; // <-to import jquery



const ApiURL="https://bisinuapi-dev.azurewebsites.net";

var tipo_id='0';
var sexo_usua='0';
var id_departamento='0';
var avatar=[];
var files_view='';

class Login extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Tipo_id:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          email_user:'',  
          codigo_email_user:'',   
          pass_1:'',
          pass_2:'',
          num_id_user:'',     
          nom1_user:'',  
          nom2_user:'',   
          apell1_user:'',
          apell2_user:'',  
          fecha_nac_user:'',  
          dir_user:'',
          cel_user:'',
          codigo_cel_user:''
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }


  GettipoID = (id) =>{
        //this.setState({tipoid:id.target.value})
        console.log(id.target.value)
        tipo_id=(id.target.value)     
  }
  
  Getsexo = (sx) =>{
      //this.setState({tipoid:id.target.value})
      console.log(sx.target.value)
      sexo_usua=(sx.target.value)     
  }
  
  Get_iddepar = (dp) =>{
    //this.setState({tipoid:id.target.value})
    console.log(dp.target.value)
    id_departamento=(dp.target.value)     
  }

  GetImg = (i) =>{      
  
    avatar=i.target.files[0]
    //files_view=URL.createObjectURL(i.target.files[0]);
    
    //$('#modal_documento').modal('show');
    //alert(avatar)
  }


  componentDidMount(){  

    if(sessionStorage.getItem('token')){
      window.location.href="/home";
    }
    
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide();
    $('#paso5').hide(); 
    $('#paso6').hide(); 

    //this.Sweetaler();
       
  }

  Sweetaler(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: true,
      timer: 5000
    })
  }


  //<- muestra modal para crear sede
  handleModalnewpunto()
  { 
    $('#modal-newpunto').modal('show');
  }


  Validar_paso0(){

    $('#paso1').show(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso1(){

    $('#paso1').hide(); 
    $('#paso2').show(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso2(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').show();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso3(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').show(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

    this.Cargar_tipo_id();
   

  }

  Validar_paso4(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').show(); 
    $('#paso6').hide(); 

    this.Cargar_departamentos();

  }

  Validar_paso5(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').show(); 

  }


  Cargar_tipo_id=async()=>{    
    
    await axios.get(ApiURL+"/api/IdentificationTypes/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Tipo_id: response.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }


  Cargar_departamentos=async()=>{    
    
    await axios.get(ApiURL+"/api/Departments")
      .then((response) => {
        console.log(response.data);
        this.setState({Departamentos: response.data.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }



   Iniciar_Sesion=async()=>{

    if(navigator.onLine) {
     
      if(this.state.form.email_user.length==0 || this.state.form.email_user=='' || this.state.form.pass_1.length==0 || this.state.form.pass_1==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa tu correo electrónico y contraseña para iniciar sesión',
          showConfirmButton: true,
          timer: 10000
        })
  
      }else{

        if(this.state.form.email_user!=='saulfernandez12@gmail.com' && this.state.form.email_user!=='furybuelvas@gmail.com' && this.state.form.email_user!=='josetomasveganegrete@gmail.com' && this.state.form.email_user!=='mathiasfernandez01205@hotmail.com' && this.state.form.email_user!=='stransito@monteria.gov.co'){

          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text:'Inicia sesión desde la APP, si no la tienes, descargala desde la Play Store o App Store',
            showConfirmButton: true,
            timer: 10000
          })

        }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
  
        await axios.post(ApiURL+"/api/Auth/Login",
  
          {
            email:this.state.form.email_user,
            password: this.state.form.pass_1.toLowerCase(),
          
          })  
  
        .then(response=>{
          console.log(response)
          //var  status  = response.status;
          //alert(status); 
          //mensaje  = response.data.message;
                
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
         
                var  token  = response.data.data.token;
                var  nombre_usua  = response.data.data.user.name1 +" "+response.data.data.user.name2+" "+response.data.data.user.lastName1+" "+response.data.data.user.lastName2;
                var  avatar  = response.data.data.user.avatar;
                var id_user = response.data.data.user.id
                var tipo_id_user = response.data.data.user.identificationType
                var num_id_user = response.data.data.user.identificationNumber
                //alert(nombre_usua); 
                sessionStorage.setItem('token',token)
                sessionStorage.setItem('nombre_usua',nombre_usua)
                sessionStorage.setItem('avatar',avatar)
                sessionStorage.setItem('id_user',id_user)
                sessionStorage.setItem('tipo_id_user',tipo_id_user)
                sessionStorage.setItem('num_id_user',num_id_user)

                if(id_user==1 || id_user==29 || id_user==1599){
                    window.location.href="/Home";
                }else{

                  window.location.href="/Reportes_interventor";

                }
  

           
          //var  dato  = response.data.token;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          
        })
        .catch(error=>{
  
          if (error.response) {
           
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);

      
       })


        }

        
        
      }

    } else {
  
     
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Atención',
        text:'Compruebe su conexión a internet e intente nuevamente',
        showConfirmButton: true,
        timer: 10000
      })
     
    }

        

   }

  Enviar_Codigo_Email=async()=>{

    if(this.state.form.email_user.length==0 || this.state.form.email_user=='' || this.state.form.pass1.length==0 || this.state.form.pass1==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa correo electrónico y contraseña para iniciar sesión',
        showConfirmButton: true,
        timer: 10000
      })

      

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })
      

      await axios.post(ApiURL+"/api/Users/RequestValidateEmail",

        {          
          email: this.state.form.email_user
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso1();
        
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })         

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

   }

  Validar_codigo_Email=async()=>{

    if(this.state.form.codigo_email_user.length==0 || this.state.form.codigo_email_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa el código enviado al correo electrónico '+ this.state.form.email_user + ' para validar su cuenta o haz Clic en "Reenviar código"',
        showConfirmButton: true,
        timer: 10000
      })

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/ResponseValidateEmail",

        {          
          email: this.state.form.email_user,
          code: this.state.form.codigo_email_user
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso2();

               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })


      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }

  }


  mostrarPassword(){
    var cambio = document.getElementById("pass_1");
  if(cambio.type == "password"){
    cambio.type = "text";
    $('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
  }else{
    cambio.type = "password";
    $('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
  }
  }


  Validar_Datosgenerales1(){

    if(tipo_id=='0' || this.state.form.num_id_user.length==0 || this.state.form.num_id_user=='' || this.state.form.nom1_user.length==0 || this.state.form.nom1_user=='' || this.state.form.apell1_user.length==0 || this.state.form.apell1_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingrese los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

     this.Validar_paso4();
     

    }

  }


  Validar_Datosgenerales2(){

    if(!moment(this.state.form.fecha_nac_user,'YYYY-MM-DD', true).isValid()){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'La fecha ingresada no es valida',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      if( this.state.form.fecha_nac_user=='' || sexo_usua=='0' || id_departamento=='0' ||  this.state.form.dir_user.length==0 || this.state.form.dir_user=='' || this.state.form.cel_user.length==0 || this.state.form.cel_user=='' || avatar.length==0 ){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingrese los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.Validar_paso5();

      }
      

     
     

    }

  }


  render(){  

        const {form} = this.state;

    return(

    <div>     

      <body class="" id="body_login">
        

        <div className="row"> 

              <div className="col-md-7">
                
              </div>   

              <div className="col-md-5">

                  <div className="card" id="card-login">
                    
                    <div className="card-header text-center">
                      
                      <a href="https://bisinu.com.co/" target="_blank" className="h1"><img id="img_logo" src={LogoBici} alt="BISINU" height="120" className="brand-image img-rounded" /></a>
                      <br></br>
                      <b> <h1 id="text-registrarte">Bienvenido</h1></b>
                     <h4 id="text-registrarte">Portal administrador</h4>
                    </div>
    
                    {/*Vista de registro paso 1 */}
                    <div className="card-body" id="paso1">   

                          <div className="row d-flex justify-content-center">

                            <div className="col-md-8">

                                <div className="form-group">                    
                               
                                  <div className="input-group mb-3">
                                
                                    <input type="email" className="form-control" autocomplete="nope" id="email_user" name="email_user" placeholder="Correo electrónico" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                      <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                      </div>
                                    </div>
                                  </div>     
                                </div>

                                <div className="form-group">                    
                                 
                                  <div className="input-group mb-3">
                                
                                  <input type="password" className="form-control" autocomplete="nope" id="pass_1" name="pass_1" placeholder="Contraseña" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                      <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                      </div>
                                    </div>
                                  </div>     
                                </div>

                                <br></br>

                                <div className="row"> 
                                  
                                  <div className="col-12">
                                    <button type="button" id="btn_registrar" className="btn btn-primary btn-block" disabled={!this.state.active_button} onClick={()=> this.Iniciar_Sesion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Iniciar sesión</button>
                                  </div>
                                 
                                </div>
          
                                <br></br>
          
                                <div className="row">
          
                                  <p className="col-6 text-center">  
                                      <div className="form-check">
                                      <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={()=> this.mostrarPassword()}></input>
                                        <label className="form-check-label" htmlFor="flexCheckDefault" id="checkpass">
                                         Mostrar contraseña
                                        </label>
                                      </div>                        
                                  </p>

                                  <p className="col-6 text-center" >
                                    <a href="#" onClick={()=> this.Validar_paso1()} id="checkpass">Olvidé mi contraseña</a>
                                  </p>
          
                                </div>

                            </div>
                            
                          </div>              
                          
                          
                         
                          
                         
                    </div>
    
    
                  </div>
                
              </div>          

        </div>
       

      </body>

    </div>


    )

  }

}


export default Login;       