import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu from '../tools/Imagenes/BISINU.png'
import Slider1 from '../tools/Imagenes/slider1.jpg'
import Slider2 from '../tools/Imagenes/slider2.jpg'
import Slider3 from '../tools/Imagenes/slider3.jpg'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion=0;


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Home_user extends Component {

  constructor()
    {
     super()
      this.state={
        Estaciones:[] , 
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          nom_estacion:'',  
          fecha_prestamo:moment().format("DD/MM/YYYY"),
          hora_prestamo:moment().format("hh:mm"),
          obser:''
          
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  componentDidMount(){  
    //$('#modal_loading').modal('show');
    
    $('#datos_soli').hide();     
    $('#google_maps').hide();
    this.Cargar_Estaciones_stock();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_acercade()
  {   
    $('#myModal_info').modal('show');
  }

  handleModal_estaciones()
  { 
   
    $('#modal_estaciones').modal('show');
  }


  handleModal_solicitud_prestamo()
  { 
   
    $('#modal_solicitud_prestamo').modal('show');
  }

  Cargar_Estaciones_stock(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/GetStationsStock")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data.filter(item => item.stationId !== 1)})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      if(this.state.Estaciones.length==0){
        $('#google_maps').hide();   
       
      }else{
        $('#google_maps').show();     
       
      }

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Seleccionar_Estacion=(estacion)=>{
   
    id_estacion=estacion.stationId
  
    //alert(id_estacion)     
    
    //alert(muni)
    
    this.setState({
      form:{
        nom_estacion:estacion.name,
        fecha_prestamo:moment().format("DD/MM/YYYY"),
        hora_prestamo:moment().format("hh:mm"),
        obser:''   
      }
    })      
    //var  dato  = servicio.id;
    //alert(this.state.form.fecha_prestamo);
    //alert(this.state.form.hora_prestamo);
  }

  Crear_solicitud=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/BikeRequests/Request",

        {
          stationId: id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        $('#modal_solicitud_prestamo').modal('hide');
        $('#modal_estaciones').modal('hide');

        this.componentDidMount();
       

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $('#modal_solicitud_prestamo').modal('hide');
          $('#modal_estaciones').modal('hide');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

       

  }

  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoBici} alt="BISINU" height="70" className="brand-image img-rounded" />
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

                <div className="row justify-content-center">
                
                  <div className="col-md-10">                    
                  
                      <AwesomeSlider cssModule={Estilo}>
                        <div data-src={Slider1} />
                        <div data-src={Slider2} />
                        <div data-src={Slider3} />
                      </AwesomeSlider>
                      
                   
                  </div>                 
                 
                </div>

                <br></br>
                <hr></hr>

                <div className="d-flex justify-content-center">
          
                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
              
                </div>

                <div className="row justify-content-center">

                   
                
                  <div className="col-md-10" >                    
                  
                      <LoadScript
                          googleMapsApiKey={mapURL}
                      >
                      <GoogleMap
                        id="marker-example"
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                      >
                      { /* Child components, such as markers, info windows, etc. */ }
                      <>     </>
    
                      {this.state.Estaciones.map(estacion=>{ return(  
                      
                      <Marker
                        icon={Logoestaciones}
                        position={{
                          lat: parseFloat(estacion.latitude),
                          lng: parseFloat(estacion.longitude)
                          }}
                        options={{
                          label:{
                            text:estacion.name,
                            className:"map-marker"
                          }
                         }}
                      />                                                                    
        
                    )})}
                 
                  
                    </GoogleMap>
                    </LoadScript>
                      
                   
                  </div>                 
                 
                </div>
                <br></br>
                <div className="row">
                  <div className="col-12">
                    <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=>{this.Cargar_Estaciones_stock();this.handleModal_estaciones()}} >Solicitar Bisinú</button>
                  </div>
                 
                </div>




              </div>

              <div className="modal fade" id="modal_estaciones" >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        
        
                        <div className="modal-body"> 

                        {this.state.Estaciones.map(estacion=>{
                          return(      
                                              
                                            
                          <div className="card" onClick={()=>{this.Seleccionar_Estacion(estacion);this.handleModal_solicitud_prestamo()}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left">{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{estacion.stock + " Disponibles"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                           )
                          }
                          )}         
                      
                        </div>  
                        <div className="modal-footer justify-content-between">        
                                
                                <button type="button" id="btn_eliminar_cuenta" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>          
        
                      </div>  
                    </div>
              </div>


              <div className="modal fade" id="modal_solicitud_prestamo" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Solicitud de prestamo - {form.nom_estacion}</h5>
                      
                    </div>
                    <div className="modal-body">

                      <div className="row" id="datos_soli">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                            
                              <input type="date" className="form-control"  name="fecha_prestamo" value={form.fecha_prestamo} min={form.fecha_prestamo} onChange={this.handleChange} />               
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                              <label >Fin:</label>
                              <input type="time" className="form-control" name="hora_prestamo" value={form.hora_prestamo} onChange={this.handleChange} />                    
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="col-md-12">

                            <div className="form-group">                    
                                    <label >Observación:</label>                           
                                    <textarea class="form-control" maxLength="100"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                    
                            </div>

                          </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_solicitud()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Solicitar </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="myModal_info" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Información de la App</h6>
                      
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-blue">
                                <h2>App Bisinú</h2>
                                <p>Versión: 1.0.1</p>
                                <b> Contactanos: desarrolloyasesorias.ideas@gmail.com</b>
                              </div>                            
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <img src={LogoBisinu} width={200} />
                              </div>                            
                            </div>
                        </div>
                        <br /> <p className="text-blue"> Copyright © 2024; Ingenieria, Desarrollos y Asesorias-IDEAS <br />
                        Todos los derechos reservados.</p>                     
                    </div>
                   
                  </div>
                </div>
            </div>

            <div className="modal fade" id="modal_loading" data-backdrop="static">
                  <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                      

                      <div className="modal-body">

                      <div className="d-flex justify-content-center">
                
                          {this.state.loading?false: <ReactBootStrap.Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />}
                  
                      </div>

                      <div class="d-flex justify-content-center">                                                
                            <spam id="spamloader"> <h5>OVH bere metal cloud server execution error 501 - AWS Management Console license - Error in execution</h5> </spam>                           
                      </div> 
                      

                      </div>
                      
                      

                    </div>  
                  </div>
            </div>



            
        </div>

        

    
    )

  }
}

export default Home_user;   