import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Registrar.css';

import LogoBici from '../tools/Imagenes/BISINUPNG.png'
import LogoBiciGIF from '../tools/Imagenes/Carga-de-logo_3.gif'

import $ from 'jquery'; // <-to import jquery



const ApiURL="https://bisinuapi-dev.azurewebsites.net";

var tipo_id='0';
var sexo_usua='0';
var id_departamento='0';
var id_ciudad='0';
var avatar=[];
var files_view='';
var file_base64='';
var tipo_file_base64='';

class Registrar extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Tipo_id:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          email_user:'',  
          codigo_email_user:'',   
          pass_1:'',
          pass_2:'',
          num_id_user:'',     
          nom1_user:'',  
          nom2_user:'',   
          apell1_user:'',
          apell2_user:'',  
          fecha_nac_user:'',  
          dir_user:'',
          cel_user:'',
          codigo_cel_user:'',
          cel_user_reeviar:''
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }


  GettipoID = (id) =>{
        //this.setState({tipoid:id.target.value})
        console.log(id.target.value)
        tipo_id=(id.target.value)     
  }
  
  Getsexo = (sx) =>{
      //this.setState({tipoid:id.target.value})
      console.log(sx.target.value)
      sexo_usua=(sx.target.value)     
  }
  
  Get_iddepar = (dp) =>{
    id_ciudad='0'
    //this.setState({tipoid:id.target.value})
    console.log(dp.target.value)
    id_departamento=(dp.target.value) 
    this.Cargar_ciudades(id_departamento);    
  }


  Get_idciudad = (cd) =>{
    //this.setState({tipoid:id.target.value})
    console.log(cd.target.value)
    id_ciudad=(cd.target.value) 
      
  }

  GetImg = (i) =>{      
  
    avatar=i.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(avatar);
    reader.onloadend =  () => {
      
      file_base64 = (reader.result).split(',')[1];
      tipo_file_base64 = (reader.result).split(';',1)[0];
      tipo_file_base64 = tipo_file_base64.split('/')[1];

    
      //alert(base64data)
      console.log(file_base64)   
    } 
    //files_view=URL.createObjectURL(i.target.files[0]);
    
    //$('#modal_documento').modal('show');
    //alert(avatar)
  }


  componentDidMount(){  
    
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide();
    $('#paso5').hide(); 
    $('#paso6').hide(); 

    //this.Sweetaler();
       
  }

  Sweetaler(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: true,
      timer: 5000
    })
  }


  //<- muestra modal para crear sede
  handleModal_reenviarsms()
  { 
    $("#cel_user_reeviar").val(""); 
    $('#modal_reenviarsms').modal('show');
  }


  Validar_paso0(){

    $('#paso1').show(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso1(){

    $('#paso1').hide(); 
    $('#paso2').show(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso2(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').show();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

  Validar_paso3(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').show(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

    this.Cargar_tipo_id();
   

  }

  Validar_paso4(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').show(); 
    $('#paso6').hide(); 

    this.Cargar_departamentos();

  }

  Validar_paso5(){

    $('#paso1').hide(); 
    $('#paso2').hide(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').show(); 

  }


  mostrarPassword(){

  var cambio = document.getElementById("pass_1");
  if(cambio.type == "password"){
    cambio.type = "text";
    $('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
  }else{
    cambio.type = "password";
    $('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
  }

  var cambio2 = document.getElementById("pass_2");
  if(cambio2.type == "password"){
    cambio2.type = "text";
    $('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
  }else{
    cambio2.type = "password";
    $('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
  }

  }


  Cargar_tipo_id=async()=>{    
    
    await axios.get(ApiURL+"/api/IdentificationTypes/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Tipo_id: response.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }


  Cargar_departamentos=async()=>{    
    
    await axios.get(ApiURL+"/api/Departments")
      .then((response) => {
        console.log(response.data);
        this.setState({Departamentos: response.data.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }

  Cargar_ciudades=async(id_departamento)=>{    
    
    await axios.get(ApiURL+"/api/Cities/"+id_departamento)
      .then((response) => {
        console.log(response.data);
        this.setState({Municipios: response.data.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }



  Enviar_Codigo_Email=async()=>{

    if(this.state.form.email_user.length==0 || this.state.form.email_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa tu correo electrónico para iniciar el registro',
        showConfirmButton: true,
        timer: 10000
      })

      this.Validar_paso0();

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/RequestValidateEmail",

        {          
          email: this.state.form.email_user
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso1();
        
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })         

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

   }

  Validar_codigo_Email=async()=>{

    if(this.state.form.codigo_email_user.length==0 || this.state.form.codigo_email_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa el código enviado al correo electrónico '+ this.state.form.email_user + ' para validar su cuenta o haz Clic en "Reenviar código"',
        showConfirmButton: true,
        timer: 10000
      })

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/ResponseValidateEmail",

        {          
          email: this.state.form.email_user,
          code: this.state.form.codigo_email_user
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso2();

               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })


      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }

  }


  Validar_Password(){

    if(this.state.form.pass_1.length==0 || this.state.form.pass_1=='' || this.state.form.pass_2.length==0 || this.state.form.pass_2==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa una contraseña y confirmala',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      if(this.state.form.pass_1 != this.state.form.pass_2){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Las contraseña no coinciden, verifique y vuelva a intentar',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.Validar_paso3();

      }

     

    }

  }


  Validar_Datosgenerales1(){

    if(tipo_id=='0' || this.state.form.num_id_user.length==0 || this.state.form.num_id_user=='' || this.state.form.nom1_user.length==0 || this.state.form.nom1_user=='' || this.state.form.apell1_user.length==0 || this.state.form.apell1_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

     this.Validar_paso4();
     

    }

  }


  Validar_Datosgenerales2(){

    if(!moment(this.state.form.fecha_nac_user,'YYYY-MM-DD', true).isValid()){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'La fecha ingresada no es valida',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      if( this.state.form.fecha_nac_user=='' || sexo_usua=='0' || id_departamento=='0' ||  this.state.form.dir_user.length==0 || this.state.form.dir_user=='' || this.state.form.cel_user.length==0 || this.state.form.cel_user=='' || avatar.length==0 ){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa los datos solicitados',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        //this.Validar_paso5();
        this.Registrar();

      }
     

    }

  }

  Registrar=async()=>{

    if(tipo_id=='0' || this.state.form.num_id_user.length==0 || this.state.form.num_id_user=='' || this.state.form.nom1_user.length==0 || this.state.form.nom1_user=='' || this.state.form.apell1_user.length==0 || this.state.form.apell1_user=='' || this.state.form.fecha_nac_user=='' || sexo_usua=='0' || id_departamento=='0' || id_ciudad=='0' ||  this.state.form.dir_user.length==0 || this.state.form.dir_user=='' || this.state.form.cel_user.length==0 || this.state.form.cel_user=='' || avatar.length==0){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })


    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/Register",

        {          
          email: this.state.form.email_user,
          typeIdentification:tipo_id,
          identificationNumber:this.state.form.num_id_user,
          name1:this.state.form.nom1_user,
          name2:this.state.form.nom2_user,
          lastName1:this.state.form.apell1_user,
          lastName2:this.state.form.apell2_user,
          birthday:this.state.form.fecha_nac_user,
          gender:sexo_usua,
          departmentId:id_departamento,
          cityId:id_ciudad,
          address:this.state.form.dir_user,
          phone:this.state.form.cel_user,
          password:this.state.form.pass_1,
          documents:[
            {
            type:"Identification",
            base64File:file_base64,
            extension:tipo_file_base64
            }
          ]

        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje+' Ingresa el mensaje de texto enviado al celula '+ this.state.form.cel_user + ' para validar y terminar tu registro o haz Clic en "Reenviar código" sinó recibiste el mensaje',
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso5();
        
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })         

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

   }


   Validar_codigo_sms=async()=>{

    if(this.state.form.codigo_cel_user.length==0 || this.state.form.codigo_cel_user==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa el mensaje de texto enviado al celula '+ this.state.form.cel_user + ' para validar terminar tu registro o haz Clic en "Reenviar código" sinó recibiste el mensaje',
        showConfirmButton: true,
        timer: 10000
      })

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/ActivateAccount",

        {          
          phone: this.state.form.cel_user,
          code: this.state.form.codigo_cel_user
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Felicidades',
          html: mensaje+' Descarga la app y disfruta de la experiencia BISINÚ',
          showConfirmButton: true,
          timer: 30000,
          imageUrl: 'https://ci4.googleusercontent.com/proxy/Otyg3V-dG5o9y-zgLFriHWPB_q8SrD2yzRm_aKhZr4JBJnVI6lz2ldTHPxtQAmw2bxRSD2AaWYkMwPvYO-zM2U1svBWefg8cuXsLwwkFbtHLo0Avd629AlFhG__nlZyiAkWTeUXFitANu3V9hlyX_ZFqTmlpVH0SjREf8QSxNFya9c3i_1k=s0-d-e1-ft#http://cdn.mcauto-images-production.sendgrid.net/135e4e96dbfaa669/bacbddac-3a97-4ba0-a64d-158ee31b0ba7/2272x897.png',
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: 'Custom image',
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso0();
        file_base64='';
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })


      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }

  }


  Reenviar_Codigo_SMS=async()=>{

    if(this.state.form.cel_user_reeviar.length==0 || this.state.form.cel_user_reeviar==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa tu celular para verificar tu cuenta',
        showConfirmButton: true,
        timer: 10000
      })

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/ResendActivationCode",

        {          
          phone: this.state.form.cel_user_reeviar
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje+' Ingresa el mensaje de texto enviado al celula '+ this.state.form.cel_user_reeviar + ' para validar y terminar tu registro o haz Clic en "Reenviar código" sinó recibiste el mensaje',
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.Validar_paso5();

        this.setState({
          form:{
            cel_user:this.state.form.cel_user_reeviar,
            codigo_cel_user:''           
          }
        })

        $('#modal_reenviarsms').modal('hide');
        
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })         

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

   }



  render(){  

        const {form} = this.state;

    return(

    <div>     

      <body class="hold-transition login-page" id="body_registrar">
        

        <div className="login-box">            

              <div className="card card-outline card-primary" id="card-registro">
                
                <div className="card-header text-center">
                  <b> <h4 id="text-registrarte">REGISTRATE</h4></b>
                  <a href="https://bisinu.com.co/" target="_blank" className="h1"><img src={LogoBici} alt="BISINU" height="200" className="brand-image img-rounded" /></a>
                </div>

                {/*Vista de registro paso 1 */}
                <div className="card-body" id="paso1">                 
                      <p className="text-center" id="msj_registro">Vamos a verificar tu cuenta</p>
                      <div className="form-group">                    
                          <label>Correo electrónico</label>
                          <div className="input-group mb-3">
                       
                            <input type="email" className="form-control" autocomplete="nope" id="email_user" name="email_user" placeholder="Correo electrónico" onChange={this.handleChange} />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-envelope" />
                              </div>
                            </div>
                          </div>     
                      </div>
                     
                      <div className="row">
                        
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" disabled={!this.state.active_button} onClick={()=> this.Enviar_Codigo_Email()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Iniciar registro </button>
                        </div>
                       
                      </div>

                      <br></br>

                      <div className="row">

                        <p className="col-6 text-center">
                          <a href="#" onClick={()=> this.Validar_paso1()}>Ya tengo un código</a>
                        </p>

                        <p className="col-6 text-center">
                          <a href="#" onClick={()=> this.handleModal_reenviarsms()} >Activar cuenta</a>
                        </p>

                      </div>
                     
                      
                     
                </div>

                {/*Vista de registro paso 2 */}
                <div className="card-body" id="paso2">                 
                      <p className="text-center" id="msj_registro">Ingresa el código enviado a tu correo</p>
                      <div className="form-group">                    
                          <label>Código de verificación</label>
                          <div className="input-group mb-3">
                       
                            <input type="text" maxLength="6" className="form-control" autocomplete="nope" placeholder="código" id="codigo_email_user" name="codigo_email_user" onChange={this.handleChange} />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-key" />
                              </div>
                            </div>
                          </div>     
                      </div>
                     
                      <div className="row">
                       
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Validar_codigo_Email()} disabled={!this.state.active_button} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Validar código </button>
                        </div>
                       
                      </div>

                      <br></br>

                      <div className="row">

                          <p className="col-6 text-center">
                            <a href="#" onClick={()=> this.Validar_paso0()}>Atrás</a>
                          </p>
    
                          <p className="col-6 text-center">
                            <a href="#" onClick={()=> this.Enviar_Codigo_Email()}>Reenviar código</a>
                          </p>

                      </div>
                     
                </div>

                {/*Vista de registro paso 3 */}
                <div className="card-body" id="paso3">                 
                      <p className="text-center" id="msj_registro">Listo! Ahora crea una contraseña</p>
                      <div className="form-group">                    
                          <label>Contraseña</label>
                          <div className="input-group mb-3">
                       
                            <input type="password" className="form-control" autocomplete="nope" id="pass_1" name="pass_1" onChange={this.handleChange} />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            </div>
                          </div>     
                      </div>

                      <div className="form-group">                    
                          <label>Confirmar contraseña</label>
                          <div className="input-group mb-3">
                       
                            <input type="password" className="form-control" autocomplete="nope" id="pass_2" name="pass_2" onChange={this.handleChange}/>
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-lock" />
                              </div>
                            </div>
                          </div>     
                      </div>

                      <div className="row">
          
                          <p className="col-12 text-center">  
                              <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={()=> this.mostrarPassword()}></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault" id="checkpass">
                                 Mostrar contraseña
                                </label>
                              </div>                        
                          </p>
                      
                      </div>
                     
                      <div className="row">
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Validar_Password()}>Continuar</button>
                        </div>
                       
                      </div>
                 
                     
                </div>

                {/*Vista de registro paso 4 */}
                <div className="card-body" id="paso4">                 
                      <p className="text-center" id="msj_registro">!Ya falta poco! Completa tu registro</p>
                      
                     

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Tipo de identificación</label>
                              <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid"  >
                    
                                  <option  value="0"> Seleccione...</option> ,
                                  {this.state.Tipo_id.map(item=>                              
                                  <option key={item.id} value={item.id}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                  )}
                                        
                              </select>
                          </div>
    
                      </div>
    
                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Número de identificación</label>
                              <input type="text" maxLength="12" className="form-control" autoComplete='nope' id="num_id_user" name="num_id_user" onChange={this.handleChange} />                       
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Primer nombre</label>
                              <input type="text" maxLength="30" className="form-control" autoComplete='nope' id="nom1_user"  name="nom1_user" onChange={this.handleChange} />                       
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Segundo nombre</label>
                              <input type="text" maxLength="30" className="form-control" autoComplete='nope' id="nom2_user"  name="nom2_user" onChange={this.handleChange} />                       
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Primer apellido</label>
                              <input type="text" maxLength="30" className="form-control" autoComplete='nope' id="apell1_user"  name="apell1_user" onChange={this.handleChange} />                       
                          </div>
    
                      </div>


                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Segundo apellido</label>
                              <input type="text" maxLength="30" className="form-control" autoComplete='nope' id="apell2_user"  name="apell2_user" onChange={this.handleChange}/>                       
                          </div>
    
                      </div>            
                     
                      <div className="row">
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Validar_Datosgenerales1()}>Continuar</button>
                        </div>
                       
                      </div>

                      <br></br>

                      <div className="row">

                          <p className="col-12 text-center">
                            <a href="#" onClick={()=> this.Validar_paso2()}>Atrás</a>
                          </p>    
                        

                      </div>
                 
                     
                </div>

                {/*Vista de registro paso 5 */}
                <div className="card-body" id="paso5">                 
                      <p className="text-center" id="msj_registro">!Últimos datos!</p>                      
                     

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Fecha de nacimiento</label>
                              <input type="date" className="form-control" id="fecha_nac_user" name="fecha_nac_user" onChange={this.handleChange}/>               
                          </div>
    
                      </div>
    
                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Sexo</label>
                              <select onChange={this.Getsexo}  className="form-control" id="sexo" name="sexo">
                    
                                  <option  value="0"> Seleccione...</option>
                                  <option  value="F">Femenino</option>
                                  <option  value="M"> Masculino</option>
                                        
                              </select>
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Departamento de residencia</label>
                              <select onChange={this.Get_iddepar}  className="form-control" id="id_depa" name="id_depa"  >
                    
                                  <option  value="0"> Seleccione...</option> ,
                                  {this.state.Departamentos.map(item=>                              
                                  <option key={item.id} value={item.id}>  {item.name ? (item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                  )}
                                        
                              </select>
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Ciudad</label>
                              <select onChange={this.Get_idciudad}  className="form-control" id="id_muni" name="id_muni"  >
                    
                                  <option  value="0"> Seleccione...</option> ,
                                  {this.state.Municipios.map(item=>                              
                                  <option key={item.id} value={item.id}>  {item.name ? (item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                  )}
                                        
                              </select>
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Dirección</label>
                              <input type="text" maxLength="30" className="form-control" autoComplete='nope' id="dir_user" name="dir_user" onChange={this.handleChange} />                       
                          </div>
    
                      </div>


                      <div className="col-md-12">
    
                          <div className="form-group">                    
                              <label >Celular</label>
                              <input type="number" maxLength="10" className="form-control" autoComplete='nope' id="cel_user" name="cel_user" placeholder="número de celular actual" onChange={this.handleChange} />                       
                          </div>
    
                      </div>

                      <div className="col-md-12">
    
                          <div className="form-group">                    
                          <label for="formFileMultiple" className="form-label">Adjuntar documento de identidad</label>
                          <input className="form-control" type="file" id="formFileMultiple" accept="image/jpg,image/jpeg,image/bmp,image/png" onChange={this.GetImg}/>
                          </div>
    
                      </div>           
                     
                      <div className="row">
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Validar_Datosgenerales2()} disabled={!this.state.active_button}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Continuar</button>
                        </div>
                       
                      </div>

                      <br></br>

                      <div className="row">

                          <p className="col-12 text-center">
                            <a href="#" onClick={()=> this.Validar_paso3()}>Atrás</a>
                          </p> 

                      </div>
                 
                     
                </div>

                {/*Vista de registro paso 6 */}
                <div className="card-body" id="paso6">                 
                      <p className="text-center" id="msj_registro">Por ultimo ingresa el código enviado por mensaje de texto a tu celular registrado</p>
                      <div className="form-group">                    
                          <label>Código SMS BISINÚ</label>
                          <div className="input-group mb-3">
                       
                            <input type="text" className="form-control" autocomplete="nope" placeholder="código" id="codigo_cel_user" name="codigo_cel_user" onChange={this.handleChange} />
                            <div className="input-group-append">
                              <div className="input-group-text">
                                <span className="fas fa-comment-dots" />
                              </div>
                            </div>
                          </div> 
                          <p className="text-center" id="msj_importante">No abandones esta página hasta no haber validado el código enviado a tu celular</p>    
                      </div>
                     
                      <div className="row">
                        <div className="col-12">
                          <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Validar_codigo_sms()} disabled={!this.state.active_button}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Registrar </button>
                        </div>
                       
                      </div>
                 
                      <br></br>

                      <div className="row">

                          {/* <p className="col-6 text-center">
                            <a href="#" onClick={()=> this.Validar_paso4()}>Atrás</a>
                          </p>   */}

                          <p className="col-12 text-center">
                            <a href="#" onClick={()=> this.handleModal_reenviarsms()} >Reenviar código</a>
                          </p>  
                        

                      </div>
                     
                </div>

               
              </div>  
                        

        </div>

        {/* <div>
           <footer className="text-center" id="footer">
              <strong>Copyright © 2022 <a id="copyright" href="">IDEAS</a> - </strong> Todos los derechos reservados.
             
            </footer>

        </div> */}

      </body>

      <div className="modal fade" id="modal_reenviarsms">
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title"><b>Activar cuenta</b></h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body">
                    
                    <p className="text-center" id="text-registrarte">Ingresa el numero de celular que actualmente utilizas para verificar tu cuenta</p>
                    <div className="form-group">                    
                        <label>Celular</label>
                        <div className="input-group mb-3">
                     
                        <input type="number" maxLength="10" className="form-control" autoComplete='nope' id="cel_user_reeviar" name="cel_user_reeviar" placeholder="número de celular actual" onChange={this.handleChange} />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-mobile" />
                            </div>
                          </div>
                        </div> 
                          
                    </div>
                    
                    <div className="row">
                      <div className="col-12">
                        <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=> this.Reenviar_Codigo_SMS()} disabled={!this.state.active_button}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Reenviar código de activación </button>
                      </div>
                     
                    </div>
                      
                </div> 

              </div>  
            </div>
      </div>


    </div>


    )

  }

}


export default Registrar;       