import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Home_user.css';

import LogoBici from '../tools/Imagenes/BISINU.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu from '../tools/Imagenes/BISINU.png'
import Slider1 from '../tools/Imagenes/slider1.jpg'
import Slider2 from '../tools/Imagenes/slider2.jpg'
import Slider3 from '../tools/Imagenes/slider3.jpg'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

const Token =sessionStorage.getItem('token');

const ApiURL="https://bisinuapi-dev.azurewebsites.net";

const mapURL = 'AIzaSyAn9QT5SOYMydKuEy6oPDtTzRWDbD0ajjs'

var id_estacion=0;
var nombre_estacion="";
var id_estacion_dest="0"
var id_bicicleta_tras="0"


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Reportes extends Component {

  constructor()
    {
     super()
      this.state={
        Estaciones:[] , 
        Estacion_granja:[],
        Estacion_golondrina:[],
        Estacion_garzones:[],
        Estacion_villaolimpica:[],
        Estacion_sena:[],
        Estacion_estacionfatima:[],
        Estacion_unicor:[],
        Estacion_upb:[],
        Estacion_parquealboraya:[],
        Estacion_caituristico:[],
        Estacion_muelleturistico:[],
        Estacion_ccnuestro:[],
        Anfitrion_granja:[],
        Anfitrion_golondrina:[],
        Anfitrion_garzones:[],
        Anfitrion_villaolimpica:[],
        Anfitrion_sena:[],
        Anfitrion_estacionfatima:[],
        Anfitrion_unicor:[],
        Anfitrion_upb:[],
        Anfitrion_parquealboraya:[],
        Anfitrion_caituristico:[],
        Anfitrion_muelleturistico:[],
        Anfitrion_ccnuestro:[],
        BicicletasXestacion:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          nom_estacion:'',  
          fecha_prestamo:moment().format("DD/MM/YYYY"),
          hora_prestamo:moment().format("hh:mm"),
          obser:''
          
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  componentDidMount(){  
    //$('#modal_loading').modal('show');
    $('#div_estaciones').hide();
    $('#datos_soli').hide();     
    $('#google_maps').hide();
    this.Cargar_Estaciones_stock();
    this.Cargar_Estaciones();
    this.Cargar_Bicicletas_en_uso();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_acercade()
  {   
    $('#myModal_info').modal('show');
  }

  handleModal_estaciones()
  { 
   
    $('#modal_estaciones').modal('show');
  }


  handleModal_solicitud_prestamo()
  { 
   
    $('#modal_solicitud_prestamo').modal('show');
  }

  Cargar_Estaciones_stock(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/ListAll?numeroPagina=1&texto=")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data.filter(item => item.id !== 1)})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      if(this.state.Estaciones.length==0){
        $('#google_maps').hide();   
       
      }else{
        $('#google_maps').show();     
       
      }

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Cargar_Estaciones(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })


    //Cargar bicicletas estacion granja
  
    axios.get(ApiURL+"/api/Stations/GetStock/2")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_granja:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
        
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Parque Las Golondrinas
  
    axios.get(ApiURL+"/api/Stations/GetStock/3")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_golondrina:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Garzones
  
    axios.get(ApiURL+"/api/Stations/GetStock/4")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_garzones:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Villa Olímpica
  
    axios.get(ApiURL+"/api/Stations/GetStock/5")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_villaolimpica:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion SENA
  
    axios.get(ApiURL+"/api/Stations/GetStock/6")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_sena:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })

    //Cargar bicicletas estacion Estación Fátima
  
    axios.get(ApiURL+"/api/Stations/GetStock/7")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_estacionfatima:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Universidad De Córdoba
  
    axios.get(ApiURL+"/api/Stations/GetStock/8")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_unicor:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Universidad Pontificia Bolivariana
  
    axios.get(ApiURL+"/api/Stations/GetStock/9")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_upb:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Parque Alboraya
  
    axios.get(ApiURL+"/api/Stations/GetStock/10")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_parquealboraya:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Cai Turístico
  
    axios.get(ApiURL+"/api/Stations/GetStock/11")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_caituristico:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Muelle Turístico
  
    axios.get(ApiURL+"/api/Stations/GetStock/12")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_muelleturistico:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Centro Comercial Nuestro Montería
  
    axios.get(ApiURL+"/api/Stations/GetStock/13")
    .then((response) => {
      console.log(response);
      this.setState({Estacion_ccnuestro:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

        $('#div_estaciones').show();
      
        this.setState({ loading: this.state.loading = true })
  
       
    })
    .catch((error) => {
      console.log(error);
    })
    

  }


  Cargar_Bicicletas_en_uso(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })


    //Cargar bicicletas estacion granja
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1681")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_granja:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
        
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Parque Las Golondrinas
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1685")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_golondrina:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Garzones
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1678")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_garzones:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Villa Olímpica
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/16")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_villaolimpica:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion SENA
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/25")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_sena:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })

    //Cargar bicicletas estacion Estación Fátima
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1683")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_estacionfatima:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Universidad De Córdoba
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1676")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_unicor:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Universidad Pontificia Bolivariana
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1684")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_upb:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Parque Alboraya
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1688")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_parquealboraya:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Cai Turístico
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/22")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_caituristico:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Muelle Turístico
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/21")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_muelleturistico:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()
  
       
    })
    .catch((error) => {
      console.log(error);
    })


    //Cargar bicicletas estacion Centro Comercial Nuestro Montería
  
    axios.get(ApiURL+"/api/StationTransfers/GetLendingsByHoster/1680")
    .then((response) => {
      console.log(response);
      this.setState({Anfitrion_ccnuestro:response.data.data.filter(item => item.state == 'activo')})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

        $('#div_estaciones').show();
      
        this.setState({ loading: this.state.loading = true })
  
       
    })
    .catch((error) => {
      console.log(error);
    })
    

  }

  Cargar_BisicletasXestacion(id_bicicleta_tras){
      
    var idestacion=id_bicicleta_tras;
    
  
  
    axios.get(ApiURL+"/api/Stations/GetStock/"+idestacion)
    .then((response) => {
      console.log(response);
      this.setState({BicicletasXestacion:response.data.data.bikes})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

    })
    .catch((error) => {
      console.log(error);
    })

  }

  Seleccionar_Estaciones_con_bicicletas=(id_estacion)=>{

    $("#estacion_destino").val($('#estacion_destino > option:first').val());   
    id_estacion_dest="0"
   
    

    this.Cargar_BisicletasXestacion(id_estacion)
  
    //alert(id_estacion)      
    
    //alert(muni)

    
    $('#modal-trasladar_bicicleta').modal('show');
    //var  dato  = servicio.id;
    //alert(servicio.id);
  }

  Trasladar_Bicicleta=async()=>{

    if(id_estacion=="0" || id_estacion_dest=="0" || id_bicicleta_tras=="0" ){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa los datos solicitados',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/StationTransfers/Transfer",

      {
        "stationOut": id_estacion,
        "stationIn": id_estacion_dest,
        "annotation": "traslado",
        "bikes": [
          {
            "bikeId": id_bicicleta_tras
          }
        ]
      })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

      
        $('#modal-newpunto').modal('hide');
        this.Cargar_Estaciones();
        this.Cargar_Bicicletas_en_uso();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

  }

  Get_id_estaciondest = (ed) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ed.target.value)
    id_estacion_dest=(ed.target.value)     
  }

  Get_id_bicicleta = (ib) =>{
    //this.setState({tipoid:id.target.value})
    console.log(ib.target.value)
    id_bicicleta_tras=(ib.target.value)     
  }

  

  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoBici} alt="BISINU" height="70" className="brand-image img-rounded" />
                </div>{/* /.container-fluid */}
              </div>
             
              <div className="card">

               
               

                <div className="d-flex justify-content-center">
          
                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
              
                </div>

                <div className="row justify-content-center">


                  <div className="col-md-10" id="div_estaciones">
                 
                  <br></br>
                           
                          {/* CARD La Granja */}
                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="2", nombre_estacion="La Granja")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>La Granja</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Transversal 5 Con Diagonal 25 </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3"> 

                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_granja.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_granja.length + " En uso"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                         
                          {/* CARD Parque Las Golondrinas */}
                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="3", nombre_estacion="Parque Las Golondrinas")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Parque Las Golondrinas</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Calle 2e Con Transversal 9</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                 

                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_golondrina.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_golondrina.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div> 

                          
                          {/* CARD Garzones */}
                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="4", nombre_estacion="Garzones")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Garzones</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Semáforo De Garzones</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_garzones.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_garzones.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div> 


                          {/* CARD Villa Olímpica */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="5", nombre_estacion="Villa Olímpica")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Villa Olímpica</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Av. Circunvalar Calle 49c</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_villaolimpica.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_villaolimpica.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD SENA */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="6", nombre_estacion="SENA")}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>SENA</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Calle 27 Con Circunvalar</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_sena.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_sena.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD Estación Fátima */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="7", nombre_estacion="Estación Calle 41")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Estación Calle 41</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Calle 41 Con Circunvalar</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_estacionfatima.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_estacionfatima.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD Universidad De Córdoba */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="8", nombre_estacion="Universidad De Córdoba")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Universidad De Córdoba</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Universidad De Córdoba</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_unicor.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_unicor.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD Universidad Pontificia Bolivariana */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="9", nombre_estacion="Universidad Pontificia Bolivariana")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Universidad Pontificia Bolivariana</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Km 8 Vía Cereté</p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_upb.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_upb.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD Parque Alboraya */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="10", nombre_estacion="Estación Alboraya")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Estación Alboraya</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> K 1w Con Calle 32a </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_parquealboraya.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_parquealboraya.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>


                          {/* CARD Cai Turístico */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="11", nombre_estacion="CAI Turístico")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>CAI Turístico</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Calle 31 Con Av. 1 </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_caituristico.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_caituristico.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>

                          {/* CARD Muelle Turístico */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="12", nombre_estacion="Muelle Turístico")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Muelle Turístico</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Calle 34 Con Av. 1 </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_muelleturistico.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_muelleturistico.length + " En uso"}  </p>
                                                                 
                                  </div>

                              </div>  
                      
                          </div>

                          {/* CARD Centro Comercial Nuestro Montería */}

                          <div className="card" onClick={()=>{this.Seleccionar_Estaciones_con_bicicletas(id_estacion="13", nombre_estacion="Centro Comercial Nuestro Montería")}} >

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>Centro Comercial Nuestro Montería</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left"> Centro Comercial Nuestro Montería </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{this.state.Estacion_ccnuestro.count + " Disponibles"}  </p>
                                      <p className="card-text" id="en_uso_estacion">{this.state.Anfitrion_ccnuestro.length + " En uso"}  </p> 
                                                                 
                                  </div>

                              </div>  
                      
                          </div>

                   
                  </div>                 
                 
                </div>
                <br></br>
                {/*<div className="row">
                  <div className="col-12">
                    <button type="button" id="btn_registrar" className="btn btn-primary btn-block" onClick={()=>{this.Cargar_Estaciones_stock();this.handleModal_estaciones()}} >Solicitar Bisinú</button>
                  </div>
                 
                </div>*/}




              </div>

              <div className="modal fade" id="modal-trasladar_bicicleta" data-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Trasladar Bicicleta</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
            
                          <div className="row">

                              <div className="col-md-6">
            
                                  <div className="form-group">                    
                                      <b id="asterisco">* </b><label >Estación origen:</label>
                                      <input type="text" className="form-control" name="estacion_origen" autoComplete="nope" value={nombre_estacion} readOnly/>                       
                                  </div>
            
                              </div>
            
                              <div className="col-md-6">
            
                                  <div className="form-group">                    
                                      <b id="asterisco">* </b><label >Estacíon destino:</label>
                                      <select onChange={this.Get_id_estaciondest}  className="form-control" id="estacion_destino" >
                                          <option  value="0"> Seleccione...</option> ,
                                          {this.state.Estaciones.map(item=>                              
                                          <option key={item.id} value={item.id}> {item.name}</option>  
                                          )}
                                                  
                                      </select>
                                  </div>
            
                              </div>
                  
                          </div> 

                          <div className="row">

                            
            
                              <div className="col-md-12">
            
                                  <div className="form-group">                    
                                      <b id="asterisco">* </b><label >Bicicleta:</label>
                                      <select onChange={this.Get_id_bicicleta}  className="form-control" id="estacion_destino" >
                                          <option  value="0"> Seleccione...</option> ,
                                          {this.state.BicicletasXestacion.map(item=>                              
                                          <option key={item.id} value={item.id}> {item.code}-{item.number}</option>  
                                          )}
                                                  
                                      </select>
                                  </div>
            
                              </div>
                  
                          </div> 

                        
                        
                    </div>
            
                    <div className="modal-footer justify-content-between">        
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Trasladar_Bicicleta()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Trasladar </button>
                      <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>

              <div className="modal fade" id="modal_estaciones" >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        
        
                        <div className="modal-body"> 

                        {this.state.Estaciones.map(estacion=>{
                          return(      
                                              
                                            
                          <div className="card" onClick={()=>{this.Seleccionar_Estacion(estacion);this.handleModal_solicitud_prestamo()}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left">{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{estacion.stock + " Disponibles"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                           )
                          }
                          )}         
                      
                        </div>  
                        <div className="modal-footer justify-content-between">        
                                
                                <button type="button" id="btn_eliminar_cuenta" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>          
        
                      </div>  
                    </div>
              </div>


              <div className="modal fade" id="modal_solicitud_prestamo" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Solicitud de prestamo - {form.nom_estacion}</h5>
                      
                    </div>
                    <div className="modal-body">

                      <div className="row" id="datos_soli">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                            
                              <input type="date" className="form-control"  name="fecha_prestamo" value={form.fecha_prestamo} min={form.fecha_prestamo} onChange={this.handleChange} />               
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                              <label >Fin:</label>
                              <input type="time" className="form-control" name="hora_prestamo" value={form.hora_prestamo} onChange={this.handleChange} />                    
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="col-md-12">

                            <div className="form-group">                    
                                    <label >Observación:</label>                           
                                    <textarea class="form-control" maxLength="100"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                    
                            </div>

                          </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_solicitud()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Solicitar </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="myModal_info" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Información de la App</h6>
                      
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-blue">
                                <h2>App Bisinú</h2>
                                <p>Versión: 1.0.1</p>
                                <b> Contactanos: desarrolloyasesorias.ideas@gmail.com</b>
                              </div>                            
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <img src={LogoBisinu} width={200} />
                              </div>                            
                            </div>
                        </div>
                        <br /> <p className="text-blue"> Copyright © 2024; Ingenieria, Desarrollos y Asesorias-IDEAS <br />
                        Todos los derechos reservados.</p>                     
                    </div>
                   
                  </div>
                </div>
            </div>

            <div className="modal fade" id="modal_loading" data-backdrop="static">
                  <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                      

                      <div className="modal-body">

                      <div className="d-flex justify-content-center">
                
                          {this.state.loading?false: <ReactBootStrap.Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />}
                  
                      </div>

                      <div class="d-flex justify-content-center">                                                
                            <spam id="spamloader"> <h5>OVH bere metal cloud server execution error 501 - AWS Management Console license - Error in execution</h5> </spam>                           
                      </div> 
                      

                      </div>
                      
                      

                    </div>  
                  </div>
            </div>



            
        </div>

        

    
    )

  }
}

export default Reportes;   