import React from "react";
import '../tools/css/Sidebar.css';
import LogoTexto from '../tools/Imagenes/Logoletter.svg'
import LogoBici from '../tools/Imagenes/Logobici.png'

var Nombre_usuario =sessionStorage.getItem('nombre_usua');
var Avatar =sessionStorage.getItem('avatar');

export default function Sidebar(){
    return(        
      

        <aside className="main-sidebar sidebar-primary elevation-4" id="sidebar">
  
            <a href="/Reportes_interventor" className="brand-link">
              <img src={LogoBici} alt="BISINU" className="brand-image img-rounded" />
              <span className="brand-text">PORTAL BISINÚ</span>
            </a>
           
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={Avatar} className="img-circle elevation-2" alt="User Image" />
                </div>
                <div className="info">
                  <a href="#" className="d-block">{Nombre_usuario.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</a>
                </div>
              </div>
             
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                  {/* Add icons to the links using the .nav-icon class
                   with font-awesome or any other icon font library */}
                 
                  
                 
                  <li className="nav-item">
                    <a href="/Reportes_interventor" className="nav-link">
                      <i className="nav-icon fas fa-home" />
                      <p>
                        Inicio                        
                      </p>
                    </a>
                  </li>
                  
                  
                  
                  <li className="nav-header">UTILITARIOS</li>
                  
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-photo-video" />
                      <p>Publicidad</p>
                    </a>
                  </li> */}
                  
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-info-circle" />
                      <p>Acerca de</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/" onClick={()=> sessionStorage.clear()} className="nav-link">
                      <i className="nav-icon fas fa-power-off" />
                      <p>Salir</p>
                    </a>
                  </li>
                  <hr></hr>
                  <p id="texto_final">
                    Version 1.0.0
                  </p>
                 

                
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>

    
    )
}